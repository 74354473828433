.sinmain2{
    width: 60%;
    height: 37vmax;
    background-color: white;
    position: relative;
    margin-top: 6.5vmax;
    transform: translateX(15vmax);
    z-index: 999;
    border-radius: 1vmax;
    top:30px;
}
.signclose{
    position: absolute; 
    right: 2px;
    font-size: 25px;
    
    }
.signflex2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
}
.signflex2 .signone2{
    width: 50%;
    height: 37vmax;
    background-color: rgb(12, 12, 12);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.signflex2 .signone2 img{
    width: 40%;
}

.signflex2 .signtwo2{
    width: 45%;
    
}
.signflex2 .signtwo2 h5{
    font-size: 1.6vmax;
    font-family:"Exo";
    font-weight: 600;
    margin-top: 1.8vmax;
    
}
.signflex2 .signtwo2 h6{
    font-size: 16px;
    margin-bottom: 1.5vmax;
    margin-top: 0.5vmax;
    color: #414141;
    font-family: "Exo";
}
.signflex2 .signtwo2 h6 span{
    color: #098895;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.signflex2 .signtwo2 input{
    width: 90%;
    font-size: 1vmax;
    padding: 0.6vmax;
    border-radius: 0.5vmax;
    border: 1px solid #ffebb0;
    height: 50px;
    margin-bottom: 0.7vmax;
}
.signflex2 .signtwo2 .eyes{
    border: none;
    font-size: 1.4vmax;
   margin-left: -4vmax;
}
.signflex2 .signtwo2 p{
    width: 95%;
    font-size: 14px;
    /* margin-top: 1vmax; */
   
}
.signflex2 .signtwo2 button{
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    padding: 0.6vmax;
    border-radius: 0.5vmax;
   background-color: #E8AE00;
   border: none;
   color: #0D0E0F;
   
}
.signflex2 .signtwo2 h4{
    font-size: 14px;
    margin-top: 1vmax;
    text-align: center;
    font-weight: 500;
}
.signflex2 .signtwo2 h3{
    font-size:14px;
    margin-top: 0.5vmax;
 
    font-weight: normal;


}
.signflex2 .signtwo2 h3 span{
     color: #098895;
     font-weight: 600;
}
.signflex2 .signtwo2 .signicon2{
    width: 100%;
    text-align: center;
    margin-top: 1vmax;
    margin-bottom: 3.5vmax;
    
}
.signflex2 .signtwo2 i{
    font-size: 1.6vmax;
    padding: 0.5vmax;
    border-radius: 2vmax;
    border: 1px solid rgba(255, 208, 0, 0.911);


}

@media (max-width:767px) {
   
    .signflex2 .signone2{
        display: none;
    }
    .sinmain2{
        width: 90%;
        height: auto;
       margin-top: 9vmax;
        transform: translateX(3vmax);
        z-index: 999;
    }
 
    .signflex2{
        justify-content: center;
    }
    
    .signflex2 .signtwo2{
        width: 100%;
        padding-left: 15px;
        padding-top: 10px;
        
    }
    .signflex2 .signtwo2 h5{
        font-size: 1.8vmax;   
    }
    .signflex2 .signtwo2 h6{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 h6 span{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 input{
        font-size: 1.6vmax;
    }
    .signflex2 .signtwo2 .ri-eye-off-fill{
        font-size: 1.8vmax;  
    }
    .signflex2 .signtwo2 p{
       
        font-size: 1.4vmax;
       
    }
    .signflex2 .signtwo2 button{  
        font-size: 1.4vmax;   
    }
    .signflex2 .signtwo2 h4{
        font-size: 1.6vmax;
    }
    .signflex2 .signtwo2 h3{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 i{
        font-size: 1.9vmax;
    }
    
   
}


@media (min-width:768px) and (max-width:1024px) {
    .signflex2 .signone2{
        display: none;
    }
    .sinmain2{
        width: 90%;
        height: auto;
       margin-top: 9vmax;
        transform: translateX(3vmax);
        z-index: 999;
    }
 
    .signflex2{
        justify-content: center;
    }
   
   
    
    .signflex2 .signtwo2{
        width: 90%;
        
    }
    .signflex2 .signtwo2 h5{
        font-size: 1.8vmax;   
    }
    .signflex2 .signtwo2 h6{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 h6 span{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 input{
        font-size: 1.6vmax;
    }
    .signflex2 .signtwo2 .ri-eye-off-fill{
        font-size: 1.8vmax;  
    }
    .signflex2 .signtwo2 p{
       
        font-size: 1.4vmax;
       
    }
    .signflex2 .signtwo2 button{  
        font-size: 1.4vmax;   
    }
    .signflex2 .signtwo2 h4{
        font-size: 1.6vmax;
    }
    .signflex2 .signtwo2 h3{
        font-size: 1.4vmax;
    }
    .signflex2 .signtwo2 i{
        font-size: 1.9vmax;
    }
    
}