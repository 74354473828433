
.messageCardSubContainer1 img{
  height: 69px;
  width: 69px;
}
.messageCardContainer{
    padding: 1.5rem;
}
.deleteCard-icon{
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.deleteCard-icon-responsive{
 display: none;
}
@media(max-width:768px){
    .messageCardContainer1 {
        flex: initial !important;
      }
    .messageCardSubContainer1 {
        display: contents;
      }
    .messageCardSubContainer1 img{
        height: 50px;
        width: 45px;
      }
      .messageCardDate{
        display: none;
      }
      .messageCardPlace{
        font-size: 14px !important;
      }
      .messageCardName{
        font-size: 12px !important;
      }
      .messageCardText{
        display: none;
        font-size: 12px !important;
      }
      .messageCardContainer {
        /* box-shadow: none !important; */
        padding: 15px !important;
      }
      .messageCardSubContainer2{
        margin-top: 0;
      }
      .deleteCard-icon{
        display: none;
      }
      .deleteCard-icon-responsive{
        display: block;
        width: 15px;
        height: 15px;
       }

}
@media only screen and (min-width: 760px) and (max-width: 1400px){
  .messageCardDate{
    display: none;
  }
  .messageCardContainer1 {
    flex: initial !important;
  }
.messageCardSubContainer1 {
    display: contents;
  }
  .messageCardText{
    display: none;
  } 
  .messageCardSubContainer2{
    margin-top: 0;
  }
}