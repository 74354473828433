.boostMainContainer{
    border-radius: 20px !important;
    padding: 2rem !important;
}
.boostTitle{
    font-size: 28px;
    font-weight: 600;
    font-family: "Exo";
}
.boostSubTitle{
    font-size: 18px;
    font-weight: 400;
    font-family: "Exo";
}
.boostContinue__btn{
    height: 50px;
    background-color: #E8AE00;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 500;
}
.budgetLabel{
    font-weight: 500;
}
.Boost{
    justify-content: center;
}

@media (max-width:768px){
    .boostMainContainer{
        width: 300px !important;
    }
    .Boost{
        justify-content: flex-start !important;
        margin-left: 4vmax;
    }
    .boostTitle{
        font-size: 22px;
    }
    .boostSubTitle{
        font-size: 15px;
    }
}
@media (min-width: 768px) and (max-width: 1024px){
    .budgetContainer{
        width: 40% !important;
    }
}