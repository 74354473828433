.ForgotPasswordMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ForgotPasswordMain .ForgotPass {
  width: 50%;
  display: flex;
  height: 20vmax;
  position: absolute;
  margin-top: 55vmax;
  align-items: center;
  justify-content: center;
}

.ForgotPasswordMain .ForgotFlex {
  width: 100%;
  height: 25vmax;
  display: flex;
  border-radius: 20px;
  background-color: white;
  justify-content: space-between;
}

.ForgotPasswordMain .ForgotFlex .ForgotOne {
  width: 50%;
  height: 25vmax;

  background-color: rgb(12, 12, 12);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.ForgotPasswordMain .ForgotFlex .ForgotOne img {
  width: 30%;
}

.ForgotPasswordMain .ForgotFlex .ForgotTwo {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  padding-left: 1vmax;
}
/* .ForgotPasswordMain .ForgotFlex .ForgotTwo input {
  width: 95%;
  outline: none;
  padding: 0.5vmax;
  border: 0.5px solid chartreuse;
  border-radius: 5px;
} */
.popupClose{
  position: absolute;
  right: -4px;
  font-size: 25px;
  top: -44px;
  cursor: pointer;
}
.ForgotPasswordMain .ForgotFlex .ForgotTwo h5 {
  font-size: 1.5vmax;
  font-family:"Exo";
  font-weight: 600;
  margin-top: 2.5vmax;
  margin-bottom: 0;
}
.ForgotPasswordMain .ForgotFlex .ForgotTwo p{
  font-size: 16px;
  font-family:"Exo";
  font-weight: 400;
  /* margin-top: 2.5vmax; */
  color: #414141;
}

.ForgotPasswordMain .ForgotFlex .ForgotTwo button {
  width: 95%;
  font-size: 1.2vmax;
  font-weight: 600;
  margin-top: 1.3vmax;
  padding: 0.6vmax;
  border-radius: 0.5vmax;
  background-color: #E8AE00;
  border: none;
  margin-bottom: 1vmax;
}

@media (max-width: 767px) {
  .ForgotPasswordMain .ForgotPass {
    width: 90%;
    height: auto;
  }
  .ForgotPasswordMain .ForgotFlex {
    width: 100%;
    height: 30vmax;

    display: flex;
    justify-content: center;
  }

  .ForgotPasswordMain .ForgotFlex .ForgotOne {
    display: none;
  }

  .ForgotPasswordMain {
    width: 90%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(2vmax);
    justify-content: flex-start;
  }

  .ForgotPasswordMain .ForgotFlex .ForgotTwo {
    width: 90%;
  }

  .ForgotPasswordMain .ForgotFlex .ForgotTwo h5 {
    font-size: 2vmax;
  }
  .ForgotPasswordMain .ForgotFlex .ForgotTwo p{
    font-size: 12px;
  }
  .popupClose{
    top: -1vmax;
    right: -1vmax;
  }
  .ForgotValidationTitle{
    font-size: 16px;
  }
  .ForgotValidationSubTitle{
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .ForgotFlex {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ForgotFlex .ForgotOne {
    display: none;
  }

  .sinmain {
    width: 90%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(3vmax);
  }

  .ForgotFlex .ForgotTwo {
    width: 90%;
  }

  .ForgotFlex .ForgotTwo h5 {
    font-size: 2vmax;
  }
  .ForgotPasswordMain .ForgotFlex{
    height: 35vmax;
  }
  .ForgotPasswordMain .ForgotFlex .ForgotOne{
    height: 35vmax;
  }
  .popupClose{
    top: -84px;
  }
  .ForgotPasswordMain .ForgotPass{
    width: 70%;
  }
}
