.thankmain2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.thankdisplay2{
    width: 55%;
    height: 35vmax;
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
   align-items: center;
   flex-direction: column;
   border-radius: 1vmax;
   margin-top: 1vmax;
   font-family: "Exo";
}
.thankdisplay2 h5{
    font-size: 26px;
    font-weight: 600;
    color: #0D0E0F;
  
}
.thankdisplay2 p{
    text-align: center;
    width: 90%;
   font-size: 15px;
   margin-top: -1.5vmax;

}
.thankdisplay2 input{
    font-size: 1vmax;
    padding: 0.6vmax;
    border-radius: 0.5vmax;
    border: 1px solid rgb(255, 208, 0);
    margin-top: 0.5vmax;
    color: rgb(206, 206, 206);
}
.thankdisplay2 textarea{
    font-size: 1vmax;
    border-radius: 0.5vmax;
    border: 1px solid rgb(255, 208, 0);
    margin-top: 0.5vmax;
}
.thankdisplay2 button{
    width: 90%;
    font-size: 1.2vmax;
    font-weight: 600;
    padding: 0.6vmax;
    border-radius: 0.5vmax;
   background-color: #E8AE00;
   border: none;
   
}
 .ratingicon{
    cursor: pointer;
    margin-top: -4vmax;
    margin-right: -1vmax;
 }
@media (max-width:600px) {
    .thankdisplay2{
        width: 90%;
        height: 60vmax;
    }
    .thankdisplay2 h5{
        font-size: 3vmax;
    }
    .thankdisplay2 p{
        width: 90%;
       font-size: 1.5vmax;
    
    }
    .thankdisplay2 button{
        font-size: 1.5vmax;
    }
    .thankdisplay2 input{
        font-size: 1.5vmax;
       
    }
    .thankdisplay2 textarea{
        font-size: 1.5vmax;
      
    }
   .thankdisplay2 .lable{
    font-size: 2vmax;
   }
   .ratingicon{
    margin-top: -9vmax;
    margin-right: -1vmax;
 }
    
}
@media (min-width:600px)  and (max-width:950px) {
    .thankdisplay2{
        /* width: 90%; */
        height: 40vmax;        
    }
    .thankdisplay h5{
        font-size: 3vmax;
    }
    .thankdisplay p{
        width: 70%;
       font-size: 1.5vmax;
    
    }
    .thankdisplay button{
        font-size: 1.5vmax;
       margin-top: 19vmax;   
    }
    .ratingicon{
        margin-top: -9vmax;
        margin-right: -1vmax;
     }
}