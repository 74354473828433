.PasswordChnage{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.PasswordChnage .passwordsuccess{
          width: 40%;
   background: #fff;
   padding: 2vmax;
          box-shadow: 2px 2px 5px 2px rgba(243, 243, 243, 0.308);
          border-radius: 10px;
          margin-top: 15vmax;
}
.PasswordChnage .passwordsuccess h5{
         font-weight: 700;
         font-size: 2vmax;
}

@media (max-width:767px){
       .PasswordChnage .passwordsuccess{
              width: 90%;
              border-radius: 5px;
    }
   
    .PasswordChnage .passwordsuccess h5{
             font-size: 2.5vmax;
    }
}
@media (min-width:767px) and (max-width:1050px){
       .PasswordChnage .passwordsuccess{
              width: 90%;
              border-radius: 5px;
    }
   
    .PasswordChnage .passwordsuccess h5{
             font-size: 2.5vmax;
    }
}