.showTabBack {
    display: none;
  }
  .profileTitle{
    font-size: 28px;
  }
  .profileSubTitle{
    font-size: 24px;
  }
  @media (max-width: 768px) {
    .showTabBack {
      display: block;
    }
    .profileTitle{
        display: flex;
        margin-left: 0;
        font-size: 16px;
    }
    .profileSubTitle{
        font-size: 18px;
      }
  }