.DeleteProperty {
  width: 100%;
}
.DeleteProperty .Deleted {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10vmax;
}
.DeleteProperty .Deleted .Deletepop {
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 20px;
}
.DeleteProperty .Deleted .Deletepop h5 {
  font-weight: 700;
  font-size: 1.5vmax;
  /* margin-top: 1vmax; */
}
.DeleteProperty .Deleted .Deletepop p {
  text-align: center;
}
.DeleteProperty .Deleted .Deletepop .desidebtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1vmax;
}
.DeleteProperty .Deleted .Deletepop .desidebtn button {
  padding: 0.5vmax 3vmax;
  font-weight: 600;
  border-radius: 5px;
  background-color: red;
}

@media (max-width: 767px) {
  .DeleteProperty .Deleted .Deletepop {
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 20px;
  }
  .DeleteProperty .Deleted .Deletepop h5 {
    font-size: 2vmax;
  }
}
