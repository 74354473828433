.SignMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SignMain .SignIn {
  width: 50%;
  display: flex;
  height: 31vmax;
  position: absolute;
  margin-top: 50vmax;
  align-items: center;
  justify-content: center;
}

.SignMain .signflex {
  width: 100%;
  height: 34vmax;
  display: flex;
  border-radius: 1vmax;
  overflow: hidden;
  background-color: white;
  justify-content: space-between;
}

.SignMain .signflex .signone {
  width: 50%;
  height: 34vmax;

  background-color: rgb(12, 12, 12);
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignMain .signflex .signone img {
  width: 45%;
  height: 124px;
}

.SignMain .signflex .signtwo {
  width: 45%;
  /* height: 20vmax;
    overflow: hidden; */
}

.SignMain .signflex .signtwo h5 {
  font-size: 1.6vmax;
  font-family: "Exo";
  font-weight: 600;
  margin-top: -1.8vmax;
}

.SignMain .signflex .signtwo h6 {
  font-size: 16px;
  margin-bottom: 1.5vmax;
  margin-top: 0.5vmax;
  color: #414141;
  font-family: "Exo";
}

.SignMain .signflex .signtwo h6 span {
  color: #098895;
  font-size: 16px;
  font-weight: 600;
  font-family: "Exo";

  cursor: pointer;
}

.SignMain .signflex .signtwo input {
  width: 90%;
  font-size: 1vmax;
  padding: 0.6vmax;
  border-radius: 0.5vmax;
  border: 1px solid #ffebb0;
}

.SignMain .signflex .signtwo .eyes {
  border: none;
  font-size: 1.4vmax;
  margin-left: -3vmax;
  /* z-index: 999; */
}

.SignMain .signflex .signtwo p {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  /* margin-top: 1vmax; */
  text-align: start;
  margin-top: 2px;
  color: #0D0E0F;
}

.SignMain .signflex .signtwo button {
  width: 90%;
  font-size: 1.2vmax;
  font-weight: 600;
  padding: 0.6vmax;
  border-radius: 0.5vmax;
  background-color: #E8AE00;
  border: none;
}

.SignMain .signflex .signtwo h4 {
  font-size: 1.1vmax;
  margin-top: 1vmax;
  text-align: center;
  font-weight: 700;
}

.SignMain .signflex .signtwo .signicon {
  width: 100%;
  text-align: center;
  margin-top: 1vmax;
  margin-bottom: 9.8vmax;
  margin-right: 2.5vmax;
}

.SignMain .signflex .signtwo i {
  font-size: 1.6vmax;
  padding: 0.5vmax;
  border-radius: 2vmax;
  border: 1px solid rgba(255, 208, 0, 0.911);
  margin-right: 1.5vmax;
}

@media (max-width: 767px) {
  .SignMain .SignIn {
    width: 90%;

  }

  .SignMain .signflex {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    /* position: relative; */

  }
  .SignMain .signflex .signclose{
    position: absolute;
    top: -7vmax !important;
    right: -1vmax;

  }
  .SignMain .signflex .signone {
    display: none;
  }

  .SignMain {
    width: 100%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(0vmax);
  }

  .SignMain .signflex .signtwo {
    width: 100%;
  }

  .SignMain .signflex .signtwo h5 {
    font-size: 2vmax;
  }

  .SignMain .signflex .signtwo h6 {
    font-size: 1.5vmax;
  }

  .SignMain .signflex .signtwo h6 span {
    font-size: 1.5vmax;
  }

  .SignMain .signflex .signtwo input {
    font-size: 1.4vmax;
  }

  .SignMain .signflex .signtwo .eyes {
    font-size: 1.6vmax;
  }

  .SignMain .signflex .signtwo p {
    font-size: 1.4vmax;
  }

  .SignMain .signflex .signtwo button {
    font-size: 1.5vmax;
  }

  .SignMain .signflex .signtwo h4 {
    font-size: 1.6vmax;
  }

  .SignMain .signflex .signtwo .signicon {
    margin-bottom: 2.8vmax;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signflex {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .signflex .signone {
    display: none;
  }

  .sinmain {
    width: 90%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(3vmax);
  }

  .signflex .signtwo {
    width: 90%;
  }

  .signflex .signtwo h5 {
    font-size: 2vmax;
  }

  .signflex .signtwo h6 {
    font-size: 1.5vmax;
  }

  .signflex .signtwo h6 span {
    font-size: 1.5vmax;
  }

  .signflex .signtwo input {
    font-size: 1.4vmax;
  }

  .signflex .signtwo .eyes {
    font-size: 1.6vmax;
  }

  .signflex .signtwo p {
    font-size: 1.4vmax;
  }

  .signflex .signtwo button {
    font-size: 1.5vmax;
  }

  .signflex .signtwo h4 {
    font-size: 1.6vmax;
  }

  .signflex .signtwo .signicon {
    margin-bottom: 2.8vmax;
  }
  .SignMain .signflex{
    height: 39vmax;
  }
  .SignMain .SignIn{
    height: 39vmax;
    margin-top: 70vmax;
    width: 70%;
  }
  .SignMain .signflex .signone{
    height: 39vmax;
  }
  .signclose {
    margin-top: -1vmax !important;
  }
}
