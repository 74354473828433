.tourSub{
    box-shadow: 0px 3px 8px 2px #3232470d;
    margin-top: 20px;
    border: none;
    padding: 24px;

}
.tourSub p{
    font-size: 14px;
}
.tourSub2{
 background-color:#F9FAFB ;
}
.ScheduleTour img{
    width: 60px;
    height: 55px;
}
.ScheduleTour h3{
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 15px;
  }
.ScheduleTour h5{
  font-size: 18px;
}
.ScheduleTour p{
    font-size: 14px;
  }
  .tourSub2 p{
    font-size: 16px;
  }
  .tourButtons button{
    height: 50px;
    width: 160px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
  }

  @media(max-width:768px){
    .tourButtons{
      width: 100% !important;
    }
    .tourButtons button{
      height: 48px;
      width: 140px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 5px;
      padding: 11px 16px !important;
    }
    .ScheduleTour h3{
      font-size: 20px;
    }
    .ScheduleTour h5{
      font-size: 16px;
      font-weight: 600;
    }
  }