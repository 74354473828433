.main{
          padding-top: 88px;
}
.toggledashbutton{
          display: none;
}
.dashboard-tabs{
    font-weight: 600;
    box-shadow: 0px 3px 8px -1px #3232470D;
     width: 22%; 
}
@media (max-width:767px) {
         .main{
          padding-top: 10vmax;
         }
          .responsine{
                    position: absolute;
                   
          }
          .toggledashbutton{
                    display: initial;
                    margin-top: 10px;
                    padding-left: 10px;
                    border-left: 5px solid #E8AE00;
                    border-radius:12px
          }
          .dashboard-main{
            display: none;
            padding: 15px;
          }
          .main{
            /* padding-top: 88px; */
  }
  .toggledashbutton{
            display: none;
  }
  .dashboard-tabs{
      font-weight: 600;
      box-shadow: 0px 3px 8px -1px #3232470D;
       width: 22%; 
  }

         
}
.ylwborder{
    border-left: 5px solid var(--amber-600, #E8AE00);
    border-radius: 12px;
}
@media only screen and (min-width: 760px) and (max-width: 1400px){
  .dashboard-Title{
    margin-left: 2vmax;
  }
  .dashboard-tabs{

     width: 35% !important; 
}
.dashboard-icon{
  display: none;
}
.dashboard-main{
  padding: 10px !important;
  width: 500px !important;
}
.dashboardTabBtn{
  padding-left: 20px;
}
}