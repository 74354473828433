.Inactive {
  width: 100%;
  margin-bottom: 18vmax;
}

.Inactive .InactiveP {
  width: 90%;
  display: flex;
  margin-top: 0.5vmax;
  padding-left: 2vmax;
  align-items: center;
  justify-content: space-between;
}
.Inactive .InactiveP h5 {
  font-size: 1.5vmax;
  width: 30%;
  font-weight: 700;
}
.Inactive .InactiveP .linkss {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.Inactive .InactiveP .linkss span {
  margin: 1vmax;
  transition: 0.5s;
  cursor: pointer;
}

.Inactive .InactiveP .linkss span:hover {
  color: rgb(118, 153, 241);
}
.Inactive .InactiveP .linkss .active-link {
  color: blue;
  text-decoration: underline;
}
.Inactive .name {
  width: 90%;
  display: flex;
  padding-left: 2vmax;
  margin-top: 1vmax;
  align-items: flex-start;
  justify-content: flex-start;
}

.Inactive .Propertycrd {
  width: 100%;
  display: flex;
  margin-top: 13px;
  /* margin-top: 2vmax; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  /* margin-left: 30px; */
}
.Inactive .Propertycrd .CHouse {
  width: 97%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;

  height: 316px;
}
.Inactive .Propertycrd .CHouse .cLeft {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.Inactive .Propertycrd .CHouse .cLeft img {
  width: 100%;
}
.Inactive .Propertycrd .CHouse .cRight {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}
.Inactive .Propertycrd .CHouse .cRight .tp {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 1vmax;
  margin-left: 5px;
}
.Inactive .Propertycrd .CHouse .cRight .tp .price {
  width: 50%;
}
.Inactive .Propertycrd .CHouse .cRight .tp .price h5 {
  font-weight: 600;
  font-size: 36px;
}
.Inactive .Propertycrd .CHouse .cRight .tp .icons {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Inactive .Propertycrd .CHouse .cRight .tp .icons i {
  font-size: 1.3vmax;
  cursor: pointer;
}
.Inactive .Propertycrd .CHouse .cRight .md {
  width: 100%;
  margin-bottom: 0.2vmax;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Inactive .Propertycrd .CHouse .cRight .md h3 {
  color: #135966;
  font-size: 28px;
  font-weight: 600;
}
.buttonFont {
  font-size: "18px";
}
.Inactive .Propertycrd .CHouse .cRight .md p {
  font-size: 18px;
  color: #414141;
}
.Inactive .Propertycrd .CHouse .cRight .bm {
  width: 100%;
  display: flex;
  margin-bottom: 0.5vmax;
  border-radius: 12px;
  background-color: #cdcdcd1f;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 56px;
}
.Inactive .Propertycrd .CHouse .cRight .bm p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.Inactive .Propertycrd .CHouse .cRight .lt {
  width: 100%;
  display: flex;
  margin-bottom: 1vmax;
  justify-content: flex-start;
}
.Inactive .Propertycrd .CHouse .cRight .lt button {
  border: 2px solid #9edf06;
  padding: 0.5vmax 2vmax;
  border-radius: 12px;
  margin: 0.5vmax;
  height: 50px;
  font-size: 17px;
  width: 240px;
  color: #0d0e0f;
}

@media (max-width: 768px) {
  .Inactive .Propertycrd {
    width: 100%;
    /* align-items: center; */
    margin-top: 0 !important;
  }
  .Inactive .InactiveP {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .Inactive .InactiveP h5 {
    font-size: 4vmax;
    width: 100%;
  }
  .Inactive .InactiveP .linkss {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .Inactive .Propertycrd .CHouse {
    width: 95%;
    justify-content: center;
    height: 110px;
  }

  .Inactive .Propertycrd .CHouse .cLeft {
    width: 100%;
    align-items: center;
  }
  .Inactive .Propertycrd .CHouse .cLeft img {
    width: 100% !important;
  }
  .Inactive .Propertycrd .CHouse .cRight {
    width: 100%;
    padding: 0.3rem;
    height: 110px;
  }
  .Inactive .Propertycrd .CHouse .cRight .tp {
    margin-top: 1vmax;
    margin-bottom: 0 !important;
    width: 90%;
    justify-content: space-between !important;
    margin-left: 0;
  }
  .Inactive .Propertycrd .CHouse .cRight .tp .price h5 {
    font-size: 3vmax;
    margin-bottom: 0 !important;
  }
  .statusNotify{
    font-size: 12px !important;
  }

  .Inactive .Propertycrd .CHouse .cRight .tp .icons i {
    font-size: 2.5vmax;
  }
  .Inactive .Propertycrd .CHouse .cRight .bm p{
    font-size: 12px !important;
  }
}

@media (min-width: 760px) and (max-width: 1400px) {
  .Inactive .Propertycrd {
    width: 100%;
  }
  .Inactive .Propertycrd .CHouse {
    width: 100%;
    justify-content: center;
    /* flex-direction: column; */
    height: 232px !important;
  }

  .Inactive .Propertycrd .CHouse .cLeft {
    width: 100%;
    align-items: center;
  }
  .Inactive .Propertycrd .CHouse .cLeft img {
    width: 100% !important;
    height: 232px !important;
  }
  .Inactive .Propertycrd .CHouse .cRight {
    width: 100%;
  }
  .Inactive .Propertycrd .CHouse .cRight .tp {
    margin-top: 1vmax;
    justify-content: space-between !important;
  }
  .Inactive .Propertycrd .CHouse .cRight .tp .price h5 {
    font-size: 3vmax;
  }

  .Inactive .Propertycrd .CHouse .cRight .tp .icons i {
    font-size: 2.5vmax;
  }
  .Inactive .Propertycrd .CHouse .cRight .lt{
    display: none;
  }
  .Inactive .Propertycrd .CHouse .cRight .bm{
    width: 100%;
  }
}
