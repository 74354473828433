.Declined {
  width: 100%;
  margin-bottom: 18vmax;
}
.Declined .three {
  width: 90%;
  display: flex;
  margin-top: 1vmax;
  padding-left: 2vmax;
  align-items: center;
  justify-content: space-between;
}
.Declined .three h5 {
  font-size: 1.5vmax;
  width: 30%;
  font-weight: 700;
}
.Declined .three .linksss {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.Declined .three .linksss span {
  margin: 1vmax;
  transition: 0.5s;
  cursor: pointer;
}

.Declined .three .linksss span:hover {
  color: rgb(118, 153, 241);
}
.Declined .three .linksss .active-link {
  color: blue;
  text-decoration: underline;
}

.Declined .names {
  width: 90%;
  display: flex;
  padding-left: 2vmax;
  margin-top: 1vmax;
  align-items: flex-start;
  justify-content: flex-start;
}

.Declined .Propertycrds {
  width: 100%;
  display: flex;
  /* margin-top: 2vmax; */
  margin-bottom: 8vmax;
  gap: 2vmax;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
}
.Declined .Propertycrds .CHouses {
  width: 97%;
  border-radius: 12px;
  display: flex;
  margin-top: 1vmax;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;
  height: 364px;
}
.Declined .Propertycrds .CHouses .cLeft {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.Declined .Propertycrds .CHouses .cLeft img {
  width: 100%;
  height: fit-content;
}
.Declined .Propertycrds .CHouses .cRight {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}
.Declined .Propertycrds .CHouses .cRight .tp {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1vmax;
}
.Declined .Propertycrds .CHouses .cRight .tp .price {
  width: 50%;
}
.Declined .Propertycrds .CHouses .cRight .tp .price h5 {
  font-weight: 700;
  font-size: 2vmax;
}
.Declined .Propertycrds .CHouses .cRight .tp .icons {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Declined .Propertycrds .CHouses .cRight .tp .icons i {
  font-size: 1.3vmax;
  cursor: pointer;
}
.Declined .Propertycrds .CHouses .cRight .md {
  width: 100%;
  margin-bottom: 0.2vmax;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Declined .Propertycrds .CHouses .cRight .md h3 {
  color: rgb(0, 150, 150);
}
.Declined .Propertycrds .CHouses .cRight .bm {
  width: 100%;
  display: flex;
  margin-bottom: 0.5vmax;
  border-radius: 12px;
  background-color: #cdcdcd1f;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 56px;
}
.Declined .Propertycrds .CHouses .cRight .bm p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.Declined .Propertycrds .CHouses .cRight .lt {
  width: 100%;
  display: flex;
  margin-bottom: 1vmax;
  justify-content: flex-start;
}
.Declined .Propertycrds .CHouses .cRight .lt button {
  border: 2px solid #9edf06;
  padding: 0.5vmax 2vmax;
  border-radius: 12px;
  margin: 0.5vmax;
  height: 50px;
  font-size: 16px;
  width: 240px;
}
.delete-Button {
  border: 2px solid #e53935;
}

@media (max-width:768px) {
  .declinedHint{
    display: none !important;
  }
  .declinedButtons{
    display: none !important;
  }
  .Declined .Propertycrds {
    width: 100%;
    /* align-items: center; */
    margin-left: 15px;
  }

  .Declined .three {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .Declined .three h5 {
    font-size: 4vmax;
    width: 100%;
  }
  .Declined .three .linksss {
    width: 100%;
    display: flex;
    font-size: 2.5vmax;
    justify-content: space-evenly;
  }
  .Declined .Propertycrds .CHouses {
    width: 95%;
    padding-left: 0vmax;
    justify-content: center;
    /* flex-direction: column; */
    height: 110px;
  }

  .Declined .Propertycrds .CHouses .cLeft {
    width: 100%;
    align-items: center;
  }
  .Declined .Propertycrds .CHouses .cLeft img {
    width: 100% !important;
  }
  .Declined .Propertycrds .CHouses .cRight {
    width: 100%;
    height: 110px;
    padding: 0.5rem;
  }
  .Declined .Propertycrds .CHouses .cRight .tp {
    margin-top: 1vmax;
    height: 20px;
    margin-bottom: 0;
  }
  .Declined .Propertycrds .CHouses .cRight .tp .price {
    width: 95%;
    display: flex;
    justify-content: space-between;
  }
  .Declined .Propertycrds .CHouses .cRight .tp .price h5 {
    font-size: 3vmax;
    margin-bottom: 0 !important;
  }

  .Declined .Propertycrds .CHouses .cRight .tp .icons i {
    font-size: 2.7vmax;
  }
  .Declined .Propertycrds .CHouses .cRight .bm p{
    font-size: 12px !important;
  }
  .myPropertyPlace2{
    margin-bottom: 0 !important;
  }
  .myPropertyType2{
    margin-bottom: .2rem !important;
  }
}

@media (min-width: 760px) and (max-width: 1400px) {
  .Declined .Propertycrds {
    width: 100%;
  }

  .Declined .three {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .Declined .three h5 {
    font-size: 4vmax;
    width: 100%;
  }
  .Declined .three .linksss {
    width: 100%;
    display: flex;
    font-size: 2.5vmax;
    justify-content: space-evenly;
  }
  .Declined .Propertycrds .CHouses {
    width: 95%;
    padding-left: 0vmax;
    justify-content: center;
    height: 232px !important;

    /* flex-direction: column; */
  }

  .Declined .Propertycrds .CHouses .cLeft {
    width: 100%;
    align-items: center;
  }
  .Declined .Propertycrds .CHouses .cLeft img {
    width: 100% !important;
    height: 232px !important;
  }
  .Declined .Propertycrds .CHouses .cRight {
    width: 100%;
  }
  .Declined .Propertycrds .CHouses .cRight .tp {
    margin-top: 1vmax;
  }
  .Declined .Propertycrds .CHouses .cRight .tp .price h5 {
    font-size: 3vmax;
  }

  .Declined .Propertycrds .CHouses .cRight .tp .icons i {
    font-size: 2.7vmax;
  }
  .Declined .Propertycrds .CHouses .cRight .lt{
    display: none !important;
  }
  .declinedHint{
    display: none !important;
  }
  .Declined .Propertycrds .CHouses .cRight .tp .price{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Declined .Propertycrds .CHouses .cRight .bm p{
    display: flex;
    font-size: 14px !important;
  }
  .Declined .Propertycrds .CHouses .cRight .bm{
    width: 100% !important;
    background-color: white;
    padding: 0 !important;
  }
}
