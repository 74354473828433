.MyPropertymain {
  width: 100%;
}
.MyPropertymain .PropertyMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vmax;
}
.TopOne {
  width: 100%;
  border-radius: 20px;
  background-color: #ebfffd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 214px;
}

.topOneLeft {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 2vmax;
}
.topOneTitle {
  font-size: 2.2vmax;
  font-weight: 700;
  color: #0d0e0f;
}
.topOnePara {
  font-size: 18px;
  color: #0d0e0f;
  font-weight: 400;
}
.topOneButton {
  border: 1.5px solid #01b8c6;
  /* padding: 0.3vmax 1vmax; */
  border-radius: 50px;
  transition: 0.5s;
  height: 40px;
  width: 188px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topOneRight {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.topOneImg {
  width: 70%;
  height: 19vmax;
  margin-top: -5vmax;
}
/* -------------------------- */
.MyPropertymain .PropertyMain .TopLast {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.MyPropertymain .PropertyMain .TopLast .HeadingP {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MyPropertymain .PropertyMain .TopLast .HeadingP h5 {
  font-size: 1.7vmax;
  width: 30%;
  font-weight: 700;
  /* margin-top: 10px; */
  margin-bottom: 0;
}
.MyPropertymain .PropertyMain .TopLast .HeadingP .links {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media(max-width: 768px){
    display: block;
  }
}
.MyPropertymain .PropertyMain .TopLast .HeadingP .links span {
  margin: 1vmax;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
  margin-top: 0;
  /* Label/L3/regular */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */

  @media(max-width: 768px){
  /* margin: 0px; */
  }
}
.MyPropertymain .PropertyMain .TopLast .HeadingP .links span:hover {
  color: rgb(118, 153, 241);
}

.active-link {
  color: #098895;
  text-decoration: underline;
}

.MyPropertymain .PropertyMain .TopLast .activemain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.MyPropertymain .PropertyMain .TopLast .activemain .active {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 8px -1px #3232470d;

  /* box-shadow: 0px 0px 1px 0px #0C1A4B3D; */
  justify-content: space-between;
  border-radius: 12px;
  margin-top: 1vmax;
  padding: 1vmax;
  cursor: pointer;
}
.MyPropertymain .PropertyMain .TopLast .activemain .active i {
  font-size: 2vmax;
  margin-right: 0.5vmax;
  cursor: pointer;
}
.MyPropertymain .PropertyMain .TopLast .activemain .active .one {
  display: flex;
  align-items: center;
  justify-content: center;
}
.MyPropertymain .PropertyMain .TopLast .activemain .active .one p {
  margin-left: 1vmax;
  font-weight: 600;
  font-size: 17px;
  margin-top: 16px;
}

@media (max-width:760px) {
 
  .MyPropertymain {
    width: 100%;
  }
  .MyPropertymain .PropertyMain {
    width: 100%;
    /* margin-top: 5vmax; */
    flex-direction: column;
  }
  .MyPropertymain .PropertyMain .TopOne {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 2vmax;
    padding-top: 2vmax;
  }
  .MyPropertymain .PropertyMain .TopOne .LEFT {
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 0vmax;
  }
  .MyPropertymain .PropertyMain .TopOne .LEFT h5 {
    font-size: 3vmax;
  }
  .MyPropertymain .PropertyMain .TopOne .LEFT button {
    padding: 1vmax 1vmax;
  }
  .MyPropertymain .PropertyMain .TopOne .RIGHT {
    display: none;
  }
  .MyPropertymain .PropertyMain .TopOne .RIGHT img {
    width: 100%;
  }
  .MyPropertymain .PropertyMain .TopLast {
    width: 100%;
    align-items: center;
    justify-content: center;
    /* margin-top: 2vmax; */
    padding: 0 !important;
  }
  .MyPropertymain .PropertyMain .TopLast .HeadingP {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    /* padding: 2vmax; */
  }
  .MyPropertymain .PropertyMain .TopLast .HeadingP h5 {
    font-size: 14px;
    width: 100%;
  }
  .MyPropertymain .PropertyMain .TopLast .HeadingP .links {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
  .MyPropertymain .PropertyMain .TopLast .activemain {
    width: 100%;
  }
  .MyPropertymain .PropertyMain .TopLast .activemain .active {
    width: 95%;
    justify-content: space-between;
    padding: 1.5vmax;
  }
  .MyPropertymain .PropertyMain .TopLast .activemain .active i {
    font-size: 4vmax;
  }
  .MyPropertymain .PropertyMain .TopLast .activemain .active .one p {
    font-size: 14px;
  }
  .MyPropertymain .PropertyMain .TopLast .HeadingP .links span{
    font-size: 12px;
  }
  .topOneRight{
    display: none;
  }
  .topOneLeft{
    width: 100%;
  }
  .topOnePara{
    font-size: 16px;
  }
  .topOneTitle{
    font-size: 2vmax;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1400px){
  .MyPropertymain {
    width: 500px;
  }
  .MyPropertymain .PropertyMain .TopLast .HeadingP .links span{
    font-size: 16px;
  }
  .topOneRight{
    display: none;
  }
  .topOneLeft{
    width: 100%;
  }
  .topOnePara{
    font-size: 16px;
  }
  .topOneTitle{
    font-size: 2vmax;
  }
}