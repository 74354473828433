.success{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10vmax;
}
.success .successBox{
          width: 30%;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
}
.success .successBox img{
   
      position: absolute;
      top:-0.5vmax;
      right: -3px;
}
.success .successBox h6{
          margin-top: 2vmax;
          font-size: 2vmax;
          font-weight: 700;
}
.success .successBox button{
         width: 90%;
         padding: 0.5vmax 1vmax;
         border-radius: 5px;
         font-weight: 700;
         margin-top: 20vmax;
         margin-bottom: 2vmax;
         background-color:#E8AE00;
}

@media(max-width:768px){
      .success .successBox{
            width: 65%;
            /* height: 500px; */
            
  }    
  .success .successBox h6{
      margin-top: 0;
  }
  .success .successBox p{
      font-size: 14px;
      text-align:center;
      padding: 5px;
}
}
@media (min-width: 768px) and (max-width: 1024px){
      .success .successBox{
            width: 65%;
      }
}
