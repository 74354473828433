.DeleteProperty{
          width: 100%;
}
.DeleteProperty .Deleted{
          width: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 10vmax;
}
.DeleteProperty .Deleted .Deletepop{
          width: 400px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #fff;
          padding: 24px;
          border-radius: 20px;
          height: min-content;
}
.DeleteProperty .Deleted .Deletepop h5{
          font-weight: 600;
          font-size: 27px;
          /* margin-top: 1vmax; */
 }
.DeleteProperty .Deleted .Deletepop p{
         text-align: center;
         font-size: 16px;
         line-height: 26px;
         font-weight: 500;
         color: #414141;
}
.DeleteProperty .Deleted .Deletepop .desidebtn{
         display: flex;
         width: 400px;
         align-items: center;
         justify-content: space-evenly;
         /* margin-bottom: 1vmax; */
         margin-top: 1vmax;
 }
 .DeleteProperty .Deleted .Deletepop .desidebtn button{
           padding: 0.5vmax 3vmax;
           font-weight: 600;
           border-radius: 12px;
           background-color: #E53935;
           height: 50px;
           width: 180px;
  }
  @media (max-width:767px) {
        .DeleteProperty .Deleted .Deletepop{
                width: 90%;
               
      }
      .DeleteProperty .Deleted .Deletepop h5{
        font-size: 20px;
      }
      .DeleteProperty .Deleted .Deletepop p{
        font-size: 14px;
      }
      .DeleteProperty .Deleted .Deletepop .desidebtn button{
        width: 150px;
      }
      .DeleteProperty .Deleted .Deletepop .desidebtn{
        width: 350px;
      }
  }