.DeleteAccount {
  width: 100%;
}
.DeleteAccount .DeleteAcc {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 1vmax; */
  flex-direction: column;
}
.delete-subHeading {
  font-size: 18px;
  font-weight: 600;
  color: #0d0e0f;
}
.DeleteBox h3 {
  font-size: 28px;
  font-weight: 600;
  color: #0d0e0f;
  line-height: 36px;
  margin-bottom: 20px;
}
.DeleteAccount .DeleteAcc .deleteMain {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vmax;
  background-color: #ebfffd;
  border-radius: 12px;
}
.DeleteAccount .DeleteAcc .deleteMain .dltone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DeleteAccount .DeleteAcc .deleteMain .dltone .Dlft {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 2vmax;
}
.DeleteAccount .DeleteAcc .deleteMain .dltone .Dlft h5 {
  font-size: 2.2vmax;
  font-weight: 700;
}
.DeleteAccount .DeleteAcc .deleteMain .dltone .Dlft button {
  border: 1px solid #01b8c6;
  padding: 0.3vmax 1vmax;
  border-radius: 30px;
  transition: 0.5s;
}

.DeleteAccount .DeleteAcc .deleteMain .dltone .Drgt {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.DeleteAccount .DeleteAcc .deleteMain .dltone .Drgt img {
  width: 70%;
}

@media (max-width: 768px) {
  .DeleteBox h3{
    font-size: 16px;
  }
  .delete-subHeading{
    font-size: 16px;
  }
  .DeleteAccount .DeleteAcc .deleteMain .dltone .Dlft {
    width: 100%;
    margin-bottom: 2vmax;
    margin-top: 2vmax;
  }
  .DeleteAccount .DeleteAcc .deleteMain .dltone .Dlft button {
    padding: 0.9vmax 1vmax;
  }
  .DeleteAccount .DeleteAcc .deleteMain .dltone .Drgt {
    display: none;
  }
  .deleteCheckText{
    font-size: 14px;
  }
  .deleteButtonContainer{
    margin-top: 6vmax;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .delete-subHeading{
    font-size: 16px;
  }
  .deleteCheckText{
    font-size: 14px;
    margin-bottom: 0 !important;
  }
}