.active-card {
  border-radius: 12px;
  /* border: 1px solid rgb(223, 220, 220); */
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;
  width: 1080px;
  /* margin-left: 15px; */
}

.review {
  opacity: 0.7;
  height: 246px;
}

.active-cardIcons {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(252, 252, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.myPropertyIcon2 {
  display: none;
}
.myPropertyContainer3{
  background-color: rgb(249, 250, 251);
}
@media (max-width: 758px) {
  .active-card {
    width: 95%;
    height: 110px;
  }
  .myPropertyImg {
    width: 100px !important;
    height: 110px !important;
  }
  .myPropertyIcon {
    display: none !important;
  }
  .myPropertyIcon2 {
    display: flex;
  }
  .myPropertyPrice {
    font-size: 14px !important;
  }
  .myPropertyContainer1 {
    width: 86% !important;
    margin-bottom: 0 !important;
    margin-top: 8px !important;
    height: 20px !important;
    margin-left: 14px;
  }
  .myPropertyType {
    font-size: 14px !important;
  }
  .myPropertyContainer2 {
    width: 227px !important;

    height: 50px !important;
    margin-top: 5px;
    /* margin-left: 14px !important; */
  }
  .myPropertyPlace {
    font-size: 12px !important;
  }
  .myPropertySubContainer2 {
    height: 20px !important;
    margin-bottom: 5px !important;
  }
  .myPropertyContainer3 {
    height: 28px !important;
    width: 227px !important;
    background-color: white !important;
    padding: 0 !important;
    margin-top: 3px;
  }
  .myPropertyContainer3-margin {
    margin-left: 14px !important;
  }
  .myPropertyContainerTop3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .myPropertyContainer3__icons {
    width: 16px !important;
    height: 16px !important;
  }
  .myPropertyContainer3__iconsText {
    font-size: 12px !important;
  }
  .myPropertyContainer3__iconsSpan {
    display: none;
  }
  .myPropertySubContainer3 {
    flex: none !important;
  }
  .myPropertySubContainer2__main {
    padding-left: 0 !important;
  }
  .myPropertyImg__total {
    display: none !important;
  }
  .myPropertySubContainer3__main {
    justify-content: space-between;
    padding-right: 10px;
  }
  .myPropertyTitle {
    font-size: 14px !important;
    margin-right: 0 !important;
  }
  .myPropertyOptions {
    width: 260px !important;
  }
  .myPropertyBackIcon {
    width: 14px !important;
    height: 14px !important;
  }
  .myPropertyTotalValue {
    padding-left: 20px !important;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1400px) {
  .active-card {
    width: 465px !important;
    height: 210px !important;
  }
  .myPropertyImg {
    width: 183px !important;
    height: 210px !important;
  }
  .myPropertyContainer3 {
    width: 90% !important;
    margin-left: 0 !important;
    padding: 10px !important;
  }
  .myPropertyIcon {
    display: none !important;
  }
  .myPropertyIcon2 {
    display: block !important;
  }
  .myPropertyContainer1 {
    width: 249px !important;
    margin-left: 1.2vmax !important;
    margin-bottom: 2px !important;

  }
  .myPropertyContainer3__iconsSpan {
    display: none !important;
  }
  .myProperty-Top2 {
    width: 460px !important;
    margin-left: 5px !important;

  }
  .myPropertyPrice {
    font-size: 20px !important;
  }
  .myPropertyContainer2{
    margin-left: 5px !important;
  }
  .myPropertyType{
    font-size: 20px !important;
  }
  .myPropertyPlace{
    font-size: 18px !important;
  }
  .myPropertyImg__total{
    display: none !important;
  }
  .myPropertyContainer3__iconsText {
    font-size: 11px !important;
    margin-top: 5px;
  }
  
}
