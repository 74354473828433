.DeactivateProperty{
          width: 100%;
}
.DeactivateProperty .Deactivated{
          width: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 10vmax;
}
.DeactivateProperty .Deactivated .Deactivepop{
          width: 27%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #fff;
          border-radius: 20px;
          padding: 2rem;
}
.DeactivateProperty .Deactivated .Deactivepop h5{
          font-weight: 700;
          font-size: 1.5vmax;
          margin-top: 1vmax;
 }
.DeactivateProperty .Deactivated .Deactivepop p{
         text-align: center;
}
.DeactivateProperty .Deactivated .Deactivepop .deavtivebtn{
         display: flex;
         width: 100%;
         align-items: center;
         justify-content: space-evenly;
         margin-bottom: 1vmax;
 }
 .DeactivateProperty .Deactivated .Deactivepop .deavtivebtn button{
           padding: 0.5vmax 3vmax;
           font-weight: 600;
           border-radius: 5px;
           background-color: red;
  }

  @media (max-width:767px){
        .DeactivateProperty{
                width: 100%;
      }
      .DeactivateProperty .Deactivated .Deactivepop{
                width: 90%;
      }
      .DeactivateProperty .Deactivated .Deactivepop h5{
                font-size: 3vmax;
       }
       .DeactivateProperty .Deactivated .Deactivepop .deavtivebtn button{
                 padding: 1vmax 3vmax;
        }
       
  }
  @media (min-width:767px) and (max-width:1050px){
        .DeactivateProperty{
                width: 100%;
      }
      .DeactivateProperty .Deactivated .Deactivepop{
                width: 90%;
      }
      .DeactivateProperty .Deactivated .Deactivepop h5{
                font-size: 3vmax;
       }
       .DeactivateProperty .Deactivated .Deactivepop .deavtivebtn button{
                 padding: 1vmax 3vmax;
        }
       
  }