.thankmain{
    width: 100%;
    display: flex;
    justify-content: center;
}
.thankdisplay{
    width: 45%;
    height: 20vmax;
    background-color: #fff;
    display: flex;
   align-items: center;
   flex-direction: column;
   border-radius: 1vmax;
   margin-top: 1vmax;
}
.thankdisplay h5{
    font-size: 2vmax;
    margin-top: 3vmax;
}
.thankdisplay p{
    text-align: center;
    width: 50%;
   font-size: 1.2vmax;

}
.thankdisplay button{
    width: 90%;
    font-size: 1.2vmax;
    font-weight: 600;
    padding: 0.6vmax;
    border-radius: 0.5vmax;
   background-color: #E8AE00;
   border: none;
   margin-top: 2vmax;
   
}
@media (max-width:767px) {
    .thankdisplay{
        width: 90%;
        height: auto;
        
    }
    .thankdisplay h5{
        font-size: 3vmax;
    }
    .thankdisplay p{
        width: 70%;
       font-size: 1.5vmax;
    
    }
    .thankdisplay button{
        font-size: 1.5vmax;
       margin-top: 15vmax;
       margin-bottom: 2vmax;
       
    }
}
@media (min-width:767px)  and (max-width:1024px) {
    .thankdisplay{
        width: 90%;
        
    }
    .thankdisplay h5{
        font-size: 3vmax;
    }
    .thankdisplay p{
        width: 70%;
       font-size: 1.5vmax;
    
    }
    .thankdisplay button{
        font-size: 1.5vmax;
       margin-top: 19vmax;
       
    }
}