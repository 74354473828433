.footer {
  position: relative;
  width: 100%;
  height: max-content;
}
.footer img {
  position: absolute;
  width: 100%;
  height: 25vmax;
}

.finputmain {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finput {
  width: 50%;
  background-color: white;
  padding: 0.4vmax;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5vmax;
  margin-top: -22px;
  height: 81px;
  box-shadow: 0px 3px 8px -1px #3232470D;


}

.finput input {
  width: 82%;
  padding: 0.6vmax;
  border: 1.5px solid #ffebb0;
  font-size: 1vmax;
  border-radius: 12px;
  height: 50px;
}

.finput button {
  background-color: #E8AE00;
  padding: 0.7vmax 1vmax;
  border: none;
  border-radius: 0.5vmax;
  margin-left: -1vmax;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
}

.fdisplay {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fmain {
  position: relative; /* Make sure the child elements respect the stacking context */
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 3vmax;
  padding-bottom: 1.5vmax;
  border-bottom: 1px solid rgb(116, 115, 115);
  z-index: 2; /* Place the content above the overlay */
}
.fmain .textDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
}

.fmain h6 {
  color: white;
  position: relative;
  z-index: 3;
  margin-bottom: 20px;
}

.flast {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}

.flast .flastone {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 5.7vmax;
  margin-top: 2vmax;
}

.flast .flastone i {
  background-color: white;
  color: black;
  padding: 0.5vmax 0.5vmax;
  border-radius: 50%; /* Rounded border radius */
  font-size: 1.5vmax;
  cursor: pointer;
}

.flast .flasttwo h6 {
  font-size: 0.9vmax;
  margin-right: 7.5vmax;
  margin-top: 1vmax;
}

@media (max-width: 767px) {
  .finput {
    width: 90%;
    padding: 0.5vmax;
    box-shadow: 0px 3px 8px -1px #3232470D;


  }
  .footer{
    height: 50vh;
  }

  .finput input {
    width: 60%;
    padding: 0.6vmax;
    font-size: 1.5vmax;
  }

  .finput button {
    padding: 0.5vmax 1.4vmax;
    font-size: 1.6vmax;
  }
  .fone {
    width: 40%;
  }
  .ftwo {
    width: 40%;
  }
  .fthree {
    width: 40%;
    margin-top: 2vmax;
  }
  .ffour {
    width: 40%;
    margin-top: 2vmax;
  }
  .fmain h6 {
    font-size: 1.7vmax;
    z-index: 3;
  }

  .flast .flastone {
    width: 40%;
    margin-right: 3vmax;
  }

  .flast .flastone i {
    font-size: 2vmax;
  }

  .flast .flasttwo h6 {
    font-size: 1.5vmax;
    margin-right: 3.5vmax;

  }
  .footer img {
    /* height: 50vh; */
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .footer{
    height: 30vh;
    margin-top: 5vmax;
  }
  .finput {
    width: 94%;
    padding: 0.5vmax;
  }

  .finput input {
    width: 82%;
    padding: 0.6vmax;
    font-size: 1.2vmax;
  }

  .finput button {
    padding: 0.5vmax 1.4vmax;
    font-size: 1.3vmax;
  }
  .flast .flastone {
    width: 40%;
    margin-right: 3vmax;
  }
  .flast .flastone i {
    font-size: 2vmax;
  }
  .flast .flasttwo h6 {
    font-size: 1.5vmax;
    margin-right: 6.5vmax;
  }
  .footer img{
    /* height: 35vh; */
    height: 100%;
  }
}
