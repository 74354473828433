.messageContainer{
    display: flex;
    align-items: center;
}
.messageContainer span{
    font-size: 12px;
    padding-top: 5px;
}
.messageContainer p{
    font-size: 18px;
    margin-bottom: 0;
}
.messageMain__Container{
    width: 100%;
    /* height: 335px; */
    padding: 24px;
    border-radius: 20px;
    box-shadow: 0px 3px 8px 2px #3232470d;
    margin-top: 10px
}
.messageContainer1 span{
    font-size: 12px;
    padding-top: 5px;
}
.messageContainer1 p{
    font-size: 18px;
    margin-bottom: 0;
}
.messageTitle{
    font-size: 28px;
}
.messageSubTitle{
    font-size: 18px;
}
.messageMainPlace{
    margin-left: .5rem;
}
.messageMainName{
    margin-left: .5rem;
}
.messageInputContainer{
    width: 100%;
}
.messageText2{
    margin-left: 3rem;
}
.messageTime__container{
    display: flex;
    align-items: end;
}
@media(max-width:768px){
    .messageMainPlace{
        margin-top: 0 !important;
        margin-left: .2rem;
        font-size: 16px;
    }
    .messageMainName{
        margin-left: .2rem;
        font-size: 13px;
    }
    .messageTitle{
        font-size: 16px;
    }
    .messageSubTitle{
        font-size: 14px;
    }
    .messageTotal{
        display: none;
    }
    .messageMain__Container{
        box-shadow: none;
        padding: 0;
    }
    .messageInputContainer{
        width: 90%;
        display: flex;
        margin-left: 5px;
        margin-top: 20px;
    }
    .messageInputArea{
        height: 50px;
        padding: 12px 16px;
    }
    .messageSendButton{
        height: 48px;
        width: 100px !important;
        font-size: 18px;
        padding: 12px 16px;
    }
    .messageContainer1 p{
        font-size: 16px;
    }
    .messageContainer1 span{
        font-size: 10px;
    }
    .messageContainer span{
        font-size: 10px;
    }
    .messageContainer p{
        font-size: 16px !important;
    }
    .messageTime{
        display: none;
    }
    .messageMain__Container2{
        display: flex;
    }
    .messageText2{
        margin-left: 0 !important;
        padding: 10px !important;
    }

}
@media only screen and (min-width: 760px) and (max-width: 1400px){
    .messageMain__Container2{
        display: flex;
    }
    .messageText2{
        margin-left: 0 !important;
        padding: 10px !important;
    }
    .messageTotal {
        display: none;
    }
}