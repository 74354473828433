.rentsec1 {
  width: 100%;
  height: 25vmax;
  display: flex;
  justify-content: space-between;
  background-color: #ebfffd;
  padding-top: 1.9vmax;
}
.rentsec1-left {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6vmax;
  /* background-color: red; */
}
.rentsec1-left h6 {
  /* width: 50%; */
  background-color: black;

  color: rgb(249, 245, 245);
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 40px;
  display: inline-block;
  margin-top: 1vmax;
  border-left: 5px solid var(--amber-600, #e8ae00);
  height: 48px;
}
.rentsec1-left h6 span {
  color: #01b8c6;
}
.rentsec1-left .investmentTitle{
  font-size: 50px;
  font-weight: 700;
  color: #0d0e0f;
}
.rentsec1-left h2 {
  font-size: 50px;
  font-weight: 700;
  color: #0d0e0f;
}
.rentsec1-left p {
  font-size: 22px;
  font-weight: 600;
}

.frentinput {
  width: 85%;
  background-color: white;
  padding: 0.8vmax 0.6vmax;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-top: -22px;
  margin-left: 6vmax;
  position: relative;
  font-size: 20px;
  box-shadow: 0px 8px 11px -4px #2D36430A;

}

.frentinput input {
  width: 400px;
  padding: 0.6vmax;
  border: 1px solid #ffebb0;
  font-size: 1vmax;
  border-radius: 12px;
  height: 50px;
}

.frentinput .searchrent {
  background-color: #e8ae00;
  padding: 6px;
  border: none;
  border-radius: 12px;
  margin-left: 1vmax;
  width: 121px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #0d0e0f;
}
.frentinput .filterrent {
  padding: 6px;
  border: 1.5px solid #e8ae00;
  border-radius: 12px;
  margin-left: 1vmax;
  width: 117px;
  height: 50px;
}
.selectopt {
  border-radius: 12px;
  padding: 10px;
  margin-left: 10px;
  border: 1.5px solid #ffebb0;
  width: 142px;
  height: 50px;
}
.responsivefrentinput {
  display: none;
}

@media (max-width: 767px) {
  .rentsec1 {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: space-between;
    background-color: #ebfffd;
    padding-top: 7vmax;
  }
  .rentsec1-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2vmax;
  }
  .rentsec1-left h6 {
    background-color: black;
    color: white;
    padding: 8px 12px;
    border-radius: 12px;
    margin-bottom: 10px;
    height: 32px;
    font-size: 12px;
  }
  .rentsec1-left h2 {
    font-size: 36px;
    font-weight: 700;
  }
  .rentsec1-left p {
    width: 75%;
    font-size: 1.8vmax;
    font-weight: 700;
  }

  .rentsec1 img {
    display: none;
  }
  .responsivefrentinput {
    display: initial;
    width: 92%;
    background-color: white;
    padding: 16px 12px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 12px;
    margin-top: 8px;
    margin-left:2vmax;
    box-shadow: 0px 3px 8px -1px #3232470D;


  }
  .responsivefrentinput__Container{
    width: 80% !important;
  }
  .responsivefrentinput input {
    width: 100%;
    padding: 1.2vmax;
    border: 1px solid rgb(255, 208, 0);
    font-size: 12px;
    border-radius: 12px;
  }

  .responsivefrentinput button {
    background-color: rgb(247, 181, 1);
    padding: 0.6vmax 1vmax;
    border: none;
    border-radius: 12px;
    margin-left: 1vmax;
  }
  .frentinput {
    display: none;
  }
}
@media only screen and (min-width: 360px) and (max-width: 380px){
  .rentsec1{
    height: 48vh !important;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .rentsec1-left .investmentTitle{
    font-size: 3.5vmax;
    font-weight: 700;
  }
  .rentsec1 {
    height: 372px;
  }
  .rentsec1-left {
    margin-left: 3vmax;
  }
  .rentsec1-left h6 {
    margin-top: 4vmax ;
  }
  .rentsec1-left h2 {
    font-size: 3.5vmax;
    font-weight: 700;
  }
  .rentsec1-left p {
    width: 75%;
    font-size: 1.8vmax;
    font-weight: 700;
  }
  .selectopt {
    display: none;
  }

  .frentinput {
    width: 90%;
    padding: 16px;
    margin-top: 2px;
    margin-left: 3vmax;
    box-shadow: 0px 8px 11px -4px #2d36430a;
    justify-content: flex-start;
  }

  .frentinput input {
    width: 100%;
    padding: 1vmax;
  }
  .rentsec1 img {
    width: 31vmax;
  }

  .frentinput .custom-dropdown {
    width: 80%;
  }
}
/* for rent sale investment */
.anytime {
  margin-right: 6.5vmax;
  border: 1px solid rgb(255, 208, 0);
  border-radius: 12px;
}

.featuredcardrentsaleinst {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.listingPagination{
  margin-right: 5rem;
}
.featuredimg {
  position: relative;
}
.featuredimg h3 {
  position: absolute;
  top: 2vmax;
  font-size: 1vmax;
  background-color: white;
  border-top-right-radius: 1vmax;
  border-bottom-right-radius: 1vmax;
  padding: 0.5vmax 1vmax;
}
.featuredimg .ellipse {
  width: 10px;
  height: 10px;
  display: inline;
  margin-right: 5px;
}
.featuredimg i {
  height: 2.5vmax;
  width: 2.5vmax;
  position: absolute;
  top: 1.5vmax;
  right: 1vmax;
  font-size: 1.8vmax;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.featuredcard1 {
  width: 25%;
  margin-left: 2px;
  margin-top: 20px;
  border-radius: 12px;
  /* border: 1px solid rgb(223, 220, 220); */
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;
  margin-bottom: 10px;
}

.featuredcard1 img {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.featuredcard1 h4 {
  font-size: 1.6vmax;
  font-weight: 700;
  margin-top: 1vmax;
}
.featuredcard1 h5 {
  font-size: 1.2vmax;
  color: #135966;
}
.featuredcard1 p {
  font-size: 0.8vmax;
}
.featuredblackrent {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  border-radius: 12px;
}
.featuredblackrent p {
  margin-top: 1vmax;
  font-size: 1vmax;
}
.featuredblackrent p img {
  width: 1.5vmax;
  margin-right: 5px;
  display: inline;
}
.listingTitle {
  font-size: 2.5vmax;
    margin-top: 35px;
    margin-bottom: 27px;
    margin-left: 6vmax;
    font-family: "Exo";
    font-weight: 600;
    color: #0d0e0f;
}
.propertyIndicator{
  display: flex;
  justify-content: start;
  width: 100%;
  margin-left: 6vmax;
}

@media (max-width: 767px) {
  .listingTitle {
    margin-top: 20px;
    margin-left: 2vmax;
    margin-bottom: 5px;
    font-size: 28px;
    font-weight: 600;
  }
  .propertyIndicator{

    margin-left: 2vmax;
  }

  .listingPagination{
    margin-right: 2px;
  }
  .featuredcardrentsaleinst {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .featuredimg {
    position: relative;
  }
  .featuredimg h3 {
    position: absolute;
    top: 2vmax;
    font-size: 1vmax;
    background-color: white;
    border-top-right-radius: 1vmax;
    border-bottom-right-radius: 1vmax;
    padding: 0.5vmax 1vmax;
  }
  .featuredimg i {
    height: 4vmax;
    width: 4vmax;
    top: 2.5vmax;
    font-size: 22px;
  }
  .featuredcard1 {
    width: 89%;
  }
  .featuredcard1 h4 {
    font-size: 2.2vmax;
  }
  .featuredcard1 h5 {
    font-size: 1.7vmax;
  }
  .featuredcard1 p {
    font-size: 1.4vmax;
  }
  .featuredblack p {
    font-size: 1.7vmax;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .noFeatured{
    margin-left: 3vmax;
  }
  .propertyIndicator{

    margin-left: 0;
  }
  .listingTitle {
    margin-top: 40px;
    margin-left: 3vmax;
  }
  .featuredcardrentsaleinst {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 26vmax;
    padding-left: 3vmax;
  }
  .featuredimg {
    position: relative;
  }
  .featuredimg h3 {
    position: absolute;
    top: 2vmax;
    font-size: 1vmax;
    background-color: white;
    border-top-right-radius: 1vmax;
    border-bottom-right-radius: 1vmax;
    padding: 0.5vmax 1vmax;
  }
  .featuredimg i {
    height: 4vmax;
    width: 4vmax;
    top: 2.5vmax;
  }
  .featuredcard1 {
    width: 45%;
    margin-right: 30px;
  }
  .featuredcard1 h4 {
    font-size: 2.4vmax;
  }
  .featuredcard1 h5 {
    font-size: 2vmax;
  }
  .featuredcard1 p {
    font-size: 1.6vmax;
  }
  .featuredblack p {
    font-size: 1.7vmax;
  }
}

/* forrentsignin css */
.forrentsignin {
  padding: 4vmax;

  background-color: #ebfffd;
  margin-top: 56px;
  padding-bottom: 200px;
}
.forrentsignin h3 {
  font-size: 2.7vmax;
  font-weight: 700;
  margin-bottom: 1vmax;
}
.forrentsignin p {
  font-size: 1.2vmax;
}
.forrentsignin button {
  border: none;
  border-radius: 12px;
  background-color: #01b8c6;
  color: white;
  padding: 0.5vmax;
}

/* search for sale css start */

.searchsec1 {
  /* width: 13%; */
  background-color: black;
  color: white;
  border-radius: 12px;
  /* padding: 2px; */
  margin-left: 6vmax;
  margin-top: 30px;
  display: inline-block;
  padding: 15px 24px;
  border-left: 5px solid var(--amber-600, #e8ae00);
  height: 48px;
}
.searchsec1 span {
  color: #01b8c6;
}

.searchsec2 img {
  width: 88%;
  height: 30vmax;
  margin-left: 6vmax;
  margin-top: 2vmax;
  border-radius: 12px;
}

.searchinput {
  background-color: white;
  padding: 0.4vmax;
  display: inline-flex;
  gap: 0.6rem;
  align-items: center;
  border-radius: 12px;
  margin-top: -48px;
}

.searchinput input {
  width: 24vmax;
  padding: 0.6vmax;
  border: 1px solid rgb(255, 208, 0);
  font-size: 1vmax;
  border-radius: 12px;
}

.searchinput button {
  background-color: rgb(247, 181, 1);
  padding: 0.6vmax 1vmax;
  border: none;
  border-radius: 12px;
  margin-left: 1vmax;
}
.searchinput .selectopt {
  width: 10vmax;
  border-radius: 12px;
  padding: 10px;
}

.oxford {
  font-size: 2.6vmax;
  margin-left: 6vmax;
  margin-top: 35px;
  font-weight: 600;
  @media (max-width: 767px) {
    margin-left: 2vmax;
    font-size: 26px;
  }
}
.result {
  width: 88%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6vmax;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-left: 2vmax;
  }
}
.result button {
  border: 1px solid yellow;
  border-radius: 12px;
  background-color: white;
  padding: 0.8vmax;
}
.result span {
  font-weight: 600;
}
.result h4 {
  font-size: 1.5vmax;
  color: #135966;
  word-spacing: 3px;
  font-weight: 600;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

/* -------------------------- */
