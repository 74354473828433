.messageUsername{
    font-size: 28px;
    font-weight: 600;
}
.messagePopup{
    border-radius: 20px !important;
}
.messageTerm{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
    color: #0D0E0F;
}
.messageTermSpan{
    color: #098895;
}
.messageSendBtn{
    border-radius: 12px;
    background-color: #E8AE00;
}
.messageInputContainer{
    border: 1px solid #FFEBB0;
    border-radius: 12px;
}