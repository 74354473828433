.Notification {
  width: 100%;
}
.notifyLoading{
  font-size: 20px;
  font-weight: 400;
}
.Notification .notify {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.Notification .notify .notifyMain {
  width: 100%;
  display: flex;
  background: #ebfffd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Notification .notify .notifyMain .notificationOne {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Notification .notify .notifyMain .notificationOne .Nlft {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 2vmax;
}
.Notification .notify .notifyMain .notificationOne .Nlft h5 {
  font-size: 2.2vmax;
  font-weight: 700;
}
.Notification .notify .notifyMain .notificationOne .Nlft button {
  border: 1px solid #01b8c6;
  padding: 0.3vmax 1vmax;
  border-radius: 30px;
}

.Notification .notify .notifyMain .notificationOne .Nrgt {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Notification .notify .notifyMain .notificationOne .Nrgt img {
  width: 70%;
}

.Notification .notify .NotificationBox {
  width: 100%;
  display: flex;
  /* margin-top: 1vmax; */
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.Notification .notify .NotificationBox h5 {
  font-size: 1.7vmax;
  font-weight: 600;
  color: #0d0e0f;
}
.Notification .notify .NotificationBox h5 span {
  font-size: 18px;
  margin-left: 46vmax;
  font-weight: 600;

  color: #098895;
}
.Notification .notify .NotificationBox .NoticeBox {
  width: 100%;
  padding-left: 1vmax;
  margin-top: 1.2vmax;
  padding: 0.4vmax;
  border-radius: 5px;
  box-shadow: 0px 3px 8px -1px #3232470d;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.notifyProperty{
  width: 62px;
  height: 60px;
  border-radius: 5px;
  margin-top: 9px;
  margin-left: 11px;
}
.Notification .notify .NotificationBox .NoticeBox .txt {
  margin-left: 1vmax;
  margin-top: 8px;
}
.Notification .notify .NotificationBox .NoticeBox .txt h6 {
  font-weight: 600;
  color: #098895;
  font-size: 16px;
}
.Notification .notify .NotificationBox .NoticeBox .txt p {
  color: #e8ae00;
  font-size: 14px;
  font-weight: 600;
}
.unread-icon {
  position: absolute;
  top: 1;
  left: 1;
  width: 9px;
  height: 9px;
  background-color: rgb(0, 126, 126);
  border-radius: 50%;
  z-index: 1; /* Ensure icon appears above other elements */
}
.NoticeBox {
  position: relative;
}

.delete-icon {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 15px;
}

/* Delete */
.NoticeBox:hover {
  background-color: #fcfcfc;
}
.delete-icon-responsive{
  display: none;
}
/* Delete */

@media (max-width: 767px) {
  .unread-icon{
    display: none;
  }
  
  .notifyDate{
    display: none;
  }
  /* .Notification .notify .NotificationBox .NoticeBox{
    height: 50px;
  } */
 
  .notifyDelete{
    width: 12px;
    height: 15px;
  }
  .notifyProperty{
    width: 45px;
    height: 50px;
  }

  .Notification .notify .NotificationBox .NoticeBox .txt h6{
    font-size: 14px;
  }
  .Notification {
    width: 100%;
    margin-bottom: 25vmax;
  }
  .Notification .notify {
    width: 100%;
    /* align-items: center; */
  }

  .Notification .notify .notifyMain {
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne {
    width: 100%;
    flex-direction: column;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft {
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft h5 {
    font-size: 3.6vmax;
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft button {
    padding: 1.2vmax 1vmax;
  }
  .Notification .notify .notifyMain .notificationOne .Nrgt {
    display: none;
  }
  /* --------------------------- */
  .Notification .notify .NotificationBox {
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    /* margin-top: 3vmax; */
  }
  .Notification .notify .NotificationBox h5 {
    font-size: 16px;
    width: 100%;
  }
  .Notification .notify .NotificationBox h5 span {
    font-size: 14px;
    margin-left: 0vmax;
  }
  .delete-icon{
    display: none;
  }
  .delete-icon-responsive{
    display: block;
    padding: 10px;
  }
  .Notification .notify .NotificationBox .NoticeBox{
    justify-content: space-between;
  }
}

@media (min-width: 767px) and (max-width: 1050px) {
  .Notification {
    width: 100%;
  }
  .Notification .notify {
    width: 100%;
    /* align-items: center; */
  }

  .Notification .notify .notifyMain {
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne {
    width: 100%;
    flex-direction: column;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft {
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft h5 {
    font-size: 3.6vmax;
    width: 100%;
  }
  .Notification .notify .notifyMain .notificationOne .Nlft button {
    padding: 1.2vmax 1vmax;
  }
  .Notification .notify .notifyMain .notificationOne .Nrgt {
    display: none;
  }
  /* --------------------------- */
  .Notification .notify .NotificationBox {
    width: 90%;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 1vmax;
  }
  .Notification .notify .NotificationBox h5 {
    font-size: 3vmax;
    width: 100%;
  }
  .Notification .notify .NotificationBox h5 span {
    font-size: 1.6vmax;
    margin-left: 0vmax;
    margin-top: 10px;
  }
}
