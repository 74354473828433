.Changepassword {
  width: 100%;
}
.passwordTitle{
  font-size: 28px;
    font-weight: 600;
    color: rgb(13, 14, 15);
}
.Changepassword .passwordchange {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 1vmax; */
  flex-direction: column;
}

.Changepassword .passmain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Changepassword .passmain .TopOne {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ebfffd;
}
.Changepassword .passmain .TopOne .LEFT {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 2vmax;
}
.Changepassword .passmain .TopOne .LEFT h5 {
  font-size: 2.2vmax;
  font-weight: 700;
}
.Changepassword .passmain .TopOne .LEFT button {
  border: 1px solid #01b8c6;
  padding: 0.3vmax 1vmax;
  border-radius: 30px;
  transition: 0.5s;
}

.Changepassword .passmain .TopOne .RIGHT {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Changepassword .passmain .TopOne .RIGHT img {
  width: 70%;
}
.Changepassword .passwordchange .PasswordBox {
  width: 80%;
  display: flex;
  border-radius: 5px;
  padding: 2vmax;
  margin-top: 2vmax;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5vmax;
  justify-content: space-evenly;
  box-shadow: 2px 2px 5px 2px rgb(214, 214, 214);
}

.changeContainer {
  width: 100%;
  /* height: 335px; */
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0px 3px 8px 2px #3232470d;
  margin-top: 15px;

}
.changeInput {
  height: 50px;
  width: 100%;
  border: 1px solid #ffebb0;
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 20px;
}
.changeTextArea{
  width: 100%;
  border: 1px solid #ffebb0;
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 20px;
}
.Changepassword .passwordchange .PasswordBox .ipt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: 1vmax;
}
.PasswordBox .eyes {
  border: none;
  font-size: 1.4vmax;
  margin-left: -4vmax;
  position: absolute;
  right: 10px;
  top: 17px;
}
.old-password {
  width: 100%;
}
.passView {
  border: none;
  font-size: 1.2vmax;
  margin-left: -4vmax;
  position: absolute;
  right: 15px;
  opacity: 0.5;
  top: 11px;
  cursor: pointer;
}

.Changepassword .passwordchange .PasswordBox .ipt input {
  width: 100%;
  padding: 0.5vmax;
  margin-top: 1vmax;
  border-radius: 5px;
  outline: none;
  box-shadow: 2px 2px 5px 2px rgb(231, 231, 231);
}
.Changepassword .passwordchange .PasswordBox button {
  width: 20%;
  padding: 0.5vmax;
  border-radius: 5px;
  font-weight: 600;

  margin-top: 3vmax;
}

@media (max-width: 768px) {
  .passwordTitle{
    font-size: 16px;
    
  }
  .Changepassword .passmain .TopOne .LEFT {
    width: 80%;
  }
  .Changepassword .passmain .TopOne .LEFT h5 {
    font-size: 2.8vmax;
  }
  .Changepassword .passmain .TopOne .RIGHT {
    display: none;
  }
  .Changepassword .passwordchange .PasswordBox {
    width: 80%;
    margin-left: 3.5vmax;
  }
  .Changepassword .passwordchange .PasswordBox .ipt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: 1vmax;
  }
  .PasswordBox .eyes {
    border: none;
    font-size: 1.4vmax;
    margin-left: -4vmax;
    position: absolute;
    right: 10px;
    top: 17px;
  }

  .Changepassword .passwordchange .PasswordBox .ipt input {
    padding: 1.5vmax;
    font-size: 1.8vmax;
  }
  .Changepassword .passwordchange .PasswordBox button {
    width: 50%;
    padding: 0.5vmax;
    border-radius: 5px;
    font-weight: 600;
  }
  .passView{
    font-size: 2vmax;
  }
}
