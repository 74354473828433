.sinmain {
  width: 60%;
  height: 27vmax;
  background-color: white;
  position: relative;
  margin-top: 10vmax;
  transform: translateX(15vmax);
  z-index: 999;
  border-radius: 20px;
}

.signflex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.signflex .signone {
  width: 50%;
  height: 27vmax;
  background-color: rgb(12, 12, 12);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  padding-left: 3vmax;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-top: 4vmax;
}
.signclose{
  position: absolute;
  right: -5px;
  top: -5px;
  font-size: 25px;
}
.signflex .signone img {
  width: 20%;
  height: auto;
  margin-bottom: 2vmax;
}
.signflex .signone h5 {
  font-size: 64px;
  font-weight: 600;
}
.signflex .signone p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
}
.signflex .signtwo {
  width: 45%;
  margin-top: 4vmax;
}

.signflex .signtwo input {
    width: 90%;
  font-size: 1vmax;
  border-radius: 12px;
  border: 1px solid #ffebb0;
  margin-top: 0.5vmax;
  height: 50px;
  padding: 15px 16px;
  margin-bottom: 10px;
}
.cardElement1 {
  width: 90%;
  font-size: 1vmax;
  border-radius: 12px;
  border: 1px solid #ffebb0;
  margin-top: 0.5vmax;
  height: 50px;
  padding: 15px 16px;
  margin-bottom: 10px;
}
.signflex .signtwo p {
  font-size: 1vmax;
  font-weight: 600;
  margin-top: 1vmax;
  text-align: center;
}
.signflex .signtwo button {
  width: 90%;
  font-size: 18px;
  font-weight: 600;
  padding: 0.6vmax;
  border-radius: 0.5vmax;
  background-color: #E8AE00;
  border: none;
  margin-top: 1vmax;
  color: #0D0E0F;
}
.signflex .signtwo h4 {
  font-size: 1.1vmax;
  margin-top: 1vmax;
  text-align: center;
  font-weight: 700;
  margin-right: 1.5vmax;
}
.signflex .signtwo .signicon {
  width: 100%;
  text-align: center;
  margin-top: 1vmax;
  margin-bottom: 9.8vmax;
  margin-right: 2.5vmax;
}
.signflex .signtwo i {
  font-size: 1.6vmax;
  padding: 0.5vmax;
  border-radius: 2vmax;
  border: 1px solid rgba(255, 208, 0, 0.911);
  margin-right: 1.5vmax;
}

@media (max-width: 767px) {
  .signflex {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .signflex .signone {
    /* display: none; */
    width: 100%;
    border-bottom-left-radius:0px;
    border-top-left-radius:20px;
    border-top-right-radius:20px;


  }
  .sinmain {
    width: 90%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(3vmax);
  }
  .signflex .signtwo {
    width: 95%;
    margin-right: 0 !important;
    padding-left:15px;
  }

  .signflex .signtwo h5 {
    font-size: 2vmax;
  }
  .signflex .signtwo h6 {
    font-size: 1.5vmax;
  }
  .signflex .signtwo h6 span {
    font-size: 1.5vmax;
  }
  .signflex .signtwo input {
    font-size: 1.4vmax;
  }
  .signflex .signtwo .ri-eye-off-fill {
    font-size: 1.6vmax;
  }
  .signflex .signtwo p {
    font-size: 1.4vmax;
  }
  .signflex .signtwo button {
    font-size: 1.5vmax;
  }
  .signflex .signtwo h4 {
    font-size: 1.6vmax;
  }
  .signflex .signtwo .signicon {
    margin-bottom: 2.8vmax;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signflex {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .signflex .signone {
    display: none;
  }
  .sinmain {
    width: 90%;
    height: auto;
    margin-top: 11.5vmax;
    transform: translateX(3vmax);
  }
  .signflex .signtwo {
    width: 90%;
  }

  .signflex .signtwo h5 {
    font-size: 2vmax;
  }
  .signflex .signtwo h6 {
    font-size: 1.5vmax;
  }
  .signflex .signtwo h6 span {
    font-size: 1.5vmax;
  }
  .signflex .signtwo input {
    font-size: 1.4vmax;
  }
  .signflex .signtwo .ri-eye-off-fill {
    font-size: 1.6vmax;
  }
  .signflex .signtwo p {
    font-size: 1.4vmax;
  }
  .signflex .signtwo button {
    font-size: 1.5vmax;
  }
  .signflex .signtwo h4 {
    font-size: 1.6vmax;
  }
  .signflex .signtwo .signicon {
    margin-bottom: 2.8vmax;
  }
}
