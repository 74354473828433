.ManagePreferance{
          width: 100%;
}
.ManagePreferance .Preferance{
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
}

.ManagePreferance .Preferance .Managemain{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.ManagePreferance .Preferance .Managemain .mainOne{
        width: 100%;
        display: flex;
        align-items:center;
        justify-content: space-between;
        background-color: #EBFFFD;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft{
        width: 40%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-left: 2vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft h5{
       font-size: 2.2vmax;
       font-weight: 700;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft button{
  border: 1px solid #01B8C6         ;
       padding: 0.3vmax 1vmax;
       border-radius: 30px;
       transition: 0.5s;
}

.ManagePreferance .Preferance .Managemain .mainOne .rgt{
        width: 60%;
        display: flex;
        align-items:center;
        justify-content:flex-end;
}
.ManagePreferance .Preferance .Managemain .mainOne .rgt img{
      width: 70%;    
}
.ManagePreferance .Preferance  .ManageBox{
          width: 100%;
          /* margin-top: 1vmax; */
          display: flex;
          align-items: flex-start;
          justify-content:space-between;
          flex-direction: column;
}
/* .ManagePreferance .Preferance  .ManageBox h5{
          font-size: 28px;
          font-weight: 600;
} */
.ManagePreferance .Preferance  .ManageBox .prefbox{
          width: 100%;
          margin-top: 1vmax;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          padding-left: 1vmax;
          border-radius: 12px;
          box-shadow: 0px 3px 8px -1px #3232470D;
          height: 86px;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 15px;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft h6{
         font-size: 16px;
         font-weight: 600;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft p{
          font-size: 14px;
 }

 @media (max-width:768px){
  .ManagePreferance{
    width: 100%;
}
.ManagePreferance .Preferance{
    width: 100%;
    /* align-items: center; */
    justify-content: center;
}

.ManagePreferance .Preferance .Managemain .mainOne{
  width: 100%;
  align-items: center;
  padding-bottom: 2vmax;
  padding-top: 2vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft{
  width: 100%;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft h5{
 font-size: 3vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft button{
 padding: 0.8vmax 1vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .rgt{
  display: none;
}
.ManagePreferance .Preferance  .ManageBox{
  width: 100%;
}
/* .ManagePreferance .Preferance  .ManageBox h5{
  font-size: 3.5vmax;
} */
/* -------------------------------------------------------- */
.ManagePreferance .Preferance  .ManageBox .prefbox{
  width: 100%;
  padding: 0.8vmax;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft h6{
 font-size: 1.6vmax;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft p{
  font-size: 1vmax;
}  
.ManagePreferance .Preferance  .ManageBox h5{
  font-size: 16px;
}
 }
 @media (min-width:769px) and (max-width:1050px){
  .ChnageEmail{
    margin-top: 1vmax;
  }
  .ManagePreferance{
    width: 100%;
}
.ManagePreferance .Preferance{
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    margin-top: 1vmax;
}

.ManagePreferance .Preferance .Managemain .mainOne{
  width: 100%;
  align-items: center;
  padding-bottom: 2vmax;
  padding-top: 2vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft{
  width: 100%;
}
.Changepassword .passwordchange{
  margin-top: 1vmax;
}
.DeleteAccount .DeleteAcc{
  margin-top: 1vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft h5{
 font-size: 3vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .lft button{
 padding: 0.8vmax 1vmax;
}
.ManagePreferance .Preferance .Managemain .mainOne .rgt{
  display: none;
}
.ManagePreferance .Preferance  .ManageBox{
  width: 90%;
}
.ManagePreferance .Preferance  .ManageBox h5{
  font-size: 2.5vmax;
}
/* -------------------------------------------------------- */
.ManagePreferance .Preferance  .ManageBox .prefbox{
  width: 100%;
  padding: 0.8vmax;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft h6{
 font-size: 1.5vmax;
}
.ManagePreferance .Preferance  .ManageBox .prefbox .prefLeft p{
  font-size: 1vmax;
}

    
 }




 /* --------------------------------------------------------------- */
 
 .toggle-container {
        position: relative;
        width: 40px;
        height: 20px;
        background-color: #ededed;
        border-radius: 25px;
        cursor: pointer;
      }
      
      .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-color: white;
        border-radius: 25px;
        transition: 0.3s;
        box-shadow: 0px 3px 8px -1px #3232470D;


      }
     
      
      .label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: #a3cffe;
        transition: 0.3s; 
      }
     
      .toggle-container.on .slider {
        left: 50%;
      }
      
      .toggle-container.on .label {
        left: 75%;
        transform: translate(-50%, -50%);
      }
      
      .toggle-container.off .slider {
        left: 0;
        background-color: white;
        box-shadow: 0px 3px 8px -1px #3232470D;


      }
      
      .toggle-container.off .label {
        left: 25%;
        transform: translate(-50%, -50%);
      }
/* ------------------------------------------------------------------- */