.EmailSuccessMsg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.EmailSuccessMsg .mailsuccess {
  background-color: #fff;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  margin-top: 30vmax;
  padding: 1vmax;
  flex-direction: row;
  border-radius: 5px;
}
.EmailSuccessMsg .mailsuccess i {
  font-size: 1.5vmax;
  cursor: pointer;
}
.EmailSuccessMsg .mailsuccess p {
  font-weight: 600;
}

@media (max-width: 767px) {
  .EmailSuccessMsg .mailsuccess {
    width: 80%;
    align-items: start;
  }
  .EmailSuccessMsg .mailsuccess i {
    font-size: 2vmax;
  }
}
