.navbarMenu__cta{
    background-color: #e8ae00;
    height:50px;
    width:173px;
    justify-content:center;
    border-radius:12px;
    font-size:16px; 
    font-weight:500 ;
    padding:12px 16px;
    margin-top: 6vmax;
}
.navbarMenu__text{
   font-size: 16px;
   color: #0D0E0F;
   font-family: "Exo";
   font-weight: 500;
   margin-top: 3.2vmax;

}

.navbarMenu__textSelected{
    background-color: #EBFFFD;
    padding: 8px;
    border-radius: 12px;
    width: fit-content;
}

@media(max-width:768px){
.warning-subText{
    font-size: 14px;
}
}