.PasswordRestEmail{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.PasswordRestEmail .PasswordTemplate{
          width: 40%;
          background: #fff;
          border-radius: 5px;
          height: 30vmax;
          margin-top: 10vmax;
          border-radius: 20px;
          padding: 10px 16px 40px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
}
.PasswordRestEmail .PasswordTemplate .PasswordTemptop{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.5vmax;
}
.PasswordRestEmail .PasswordTemplate .PasswordTempTwo{
    font-family: "Exo";
}
.PasswordRestEmail .PasswordTemplate .PasswordTempTwo button{
          background-color: #E8AE00;
          border-radius: 5px;
          font-weight: 600;
          padding: 0.5vmax 1.5vmax;
          margin-bottom: 1vmax;
          height: 58px;
          border-radius: 12px
}

@media (max-width:767px) {
          .PasswordRestEmail .PasswordTemplate{
                    width: 90%;
                    height: auto;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTemptop{
                    margin-bottom: 2vmax;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTempTwo button{
                    padding: 1.5vmax 1.5vmax;
                    margin-bottom: 1vmax;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTempTwo h4{
            font-size: 16px;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTempTwo p{
            font-size: 14px;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTempThree p{
            font-size: 14px;
          }
          
}
@media (min-width:767px) and (max-width:1024px) {
          .PasswordRestEmail .PasswordTemplate{
                    width: 90%;
                    height: auto;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTemptop{
                    margin-bottom: 2vmax;
          }
          .PasswordRestEmail .PasswordTemplate .PasswordTempTwo button{
                    padding: 1.5vmax 1.5vmax;
                    margin-bottom: 1vmax;
          }
          
}