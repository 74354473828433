.ChnageEmail{
          width: 100%;
}
.ChnageEmail .chagemail{
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
}

.ChnageEmail .mailmain{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.ChnageEmail .mailmain .one{
        width: 100%;
        display: flex;
        align-items:center;
        justify-content: space-between;
        background-color: #EBFFFD;
        margin-bottom: 2vmax;
}
.ChnageEmail .mailmain .one .LEFT{
        width: 40%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-left: 2vmax;
}
.ChnageEmail .mailmain .one .LEFT h5{
       font-size: 2.2vmax;
       font-weight: 700;
}
.ChnageEmail .mailmain .one .LEFT button{
        border: 1px solid #01B8C6         ;
       padding: 0.3vmax 1vmax;
       border-radius: 30px;
       transition: 0.5s;
}

.ChnageEmail .mailmain .one .RIGHT{
        width: 60%;
        display: flex;
        align-items:center;
        justify-content:flex-end;
}
.ChnageEmail .mailmain .one .RIGHT img{
      width: 70%;    
}


.ChnageEmail .chagemail .MailBox{
          width: 80%;
          display: flex;
          border-radius: 5px;
          padding: 2vmax;
          margin-top: 2vmax;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 5vmax;
          justify-content: space-evenly;
           box-shadow: 2px 2px 5px 2px rgb(214, 214, 214);
}

.ChnageEmail .chagemail .MailBox p{
          width: 100%;
          font-size: 1vmax;
          font-weight: 600;

  }
  .ChnageEmail .chagemail .MailBox .inptss{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          font-size: 1vmax;
  }
  .inptss .eyes{
        border: none;
        font-size: 1.4vmax;
        margin-left: -4vmax;
        position: absolute;
        right: 10px;
        top: 17px;
  }
  .old-password{
        width: 100%;
        position: relative;
    
  }
  .ChnageEmail .chagemail .MailBox .inptss input{
          width: 100%;
          padding:0.5vmax;
          margin-top: 1vmax;
          border-radius: 5px;
          outline: none;
         box-shadow: 2px 2px 5px 2px rgb(231, 231, 231);
        
  }
  .ChnageEmail .chagemail .MailBox button{
          width: 20%;
          padding:0.5vmax;
          border-radius: 5px;
          font-weight: 600;
          background-color: rgb(200, 200, 0);
           margin-top: 3vmax;
           
  }


  @media (max-width:767px) {
        .ChnageEmail .mailmain .one .LEFT{
                width: 80%;
        }
        .ChnageEmail .mailmain .one .RIGHT{
               display: none;
        }
        .ChnageEmail .chagemail .MailBox{
                width: 100%;
      }
      .ChnageEmail .chagemail .MailBox p{
        font-size: 1.8vmax;
}
.ChnageEmail .chagemail .MailBox .inptss{
        font-size: 1.5vmax;
        padding: 1vmax;
}


.ChnageEmail .chagemail .MailBox .inptss input{   
        padding:1vmax;
        margin-top: 1.5vmax; 
}
.ChnageEmail .chagemail .MailBox button{
        width: 100%;
         margin-top: 2vmax;     
}
      
  }
