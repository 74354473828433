@tailwind base;
@tailwind components;
@tailwind utilities;

body input {
  outline: none;
}

.maillogo {
  height: 70px;
  width: 200px;
  object-fit: cover;
}
.cursorHover h5 {
  padding: 8px;
  border-radius: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0;
  font-family: "Exo", sans-serif;
}

.cursorHover .hoverLink {
  /* height: 3vmax; */
  /* transition: 0.5s; */
  z-index: 999;
  color: black;
}

.cursorHover .hoverLink:hover {
  /* background-color: #f0d90e; */
  background-color: #0aa595;
  border-radius: 14px;
  /* color: white; */
  z-index: 999;
}

.homesec1 {
  width: 100%;
  height: 40vmax;
  position: relative;
  padding-top: 4.3vmax;
  overflow-x: clip;
}

.homesecoverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.slide {
  flex: 0 0 100%;
  background-size: cover;
  background-position: center;
  /* position: absolute;
    top: 0;
    left: 0;*/
  opacity: 0;
  transition: opacity 1s ease;
}

.slide.active {
  opacity: 1;
}
.dots {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  @media (max-width: 700px) {
    bottom: 25px;
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #888;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #fff;
}

.homesec1img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.homesec1 h1 {
  width: 35%;
  position: absolute;
  left: 6%;
  top: 36%;
  color: white;

  font-weight: 900;
  font-size: 4vmax;
}
.homesec1 p {
  width: 45%;
  position: absolute;
  left: 6%;
  top: 66%;
  color: white;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
}
.homesecsale {
  width: 70%;
  padding-top: 0.5vmax;
  position: absolute;
  bottom: -2.4vmax;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  left: 6%;
  padding: 5px;
  font-family: "Exo", sans-serif;
  box-shadow: 0px 8px 11px -4px #2d36430a;
}
.homesecsale input {
  font-size: 1vmax;
  padding: 0.7vmax 1vmax;
  outline: none;
}
.homesecsale button {
  background-color: #e8ae00;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  /* padding: 5px 12px; */
  font-size: 18px;
  padding: 0.7vmax 1.2vmax;
}
.homesecsale h6 {
  cursor: pointer;
  font-size: 1.4vmax;
  margin-top: 8px;
  border-radius: 12px;
  border: 1.5px solid #fff9e6;
  padding: 0.5vmax 1vmax;
  text-align: center;
}
.homesecsale h6:hover {
  /* background-color: #e8ae00; */
  background-color: #0aa595;
}
.homesecsale .salebtn {
  background-color: #fff9e6;
}
.homesecsale .crossbtn {
  background-color: #fff9e6;
  border-radius: 50%;
}
.custom-dropdown {
  position: relative;
  width: 30%;
  input {
    width: 100%;
    /* width: 290px; */
    padding: 0.6vmax;
    border: 1px solid #ffebb0;
    font-size: 1vmax;
    border-radius: 12px;
    height: 50px;
  }
}
.custom-dropdown .crossbtn {
  background-color: #fff9e6;
  border-radius: 50%;
  position: absolute;
  right: 4%;
  transform: translateY(-50%);
  top: 50%;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1), 0px -3px 3px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #ccc; */
  border-top: none;
  border-radius: 0 0 12px 12px;
  padding: 5px 0;
  width: 100%;
}

.dropdown-options div {
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  font-size: 1vmax;
}

.dropdown-options div:hover {
  background-color: #f0f0f0;
}

.homesec1__container1 {
  width: 709px;
  height: 267px;
}
.homesec1__subContainer1 {
  width: 709px;
  height: 176px;
}
.homesec1__subContainer1 h1 {
  font-size: 80px;
  font-weight: 800;
  line-height: 88px;
}
.maillogoMobile{
  display: none;
}
@media (max-width: 767px) {
  .maillogo {
    height: 48px;
    width: 110px;
    display: none;
  }
  .navBarContainer{
    padding: 15px;
  }
  .maillogoMobile{
    display: block;
    height: 36px;
    width: 40px;
  }
  .homesec1 {
    width: 100%;
    height: 42vmax;
    position: relative;
    padding-top: 8.5vmax;
  }
  .homesecoverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
  }
  .homesec1img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .homesec1 h1 {
    width: 75%;
    top: 36%;
    font-size: 40px;
    line-height: 44px;
  }
  .homesec1 p {
    width: 65%;
    position: absolute;
    left: 6%;
    top: 63%;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  .homesecsale {
    width: 88%;
    position: absolute;
    bottom: -1vmax;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    bottom: -15vmax;
    padding: 16px 12px;
    box-shadow: 0px 3px 8px -1px #3232470d;
    /* height: 13vmax; */
  }
  .homesecsale h6 {
    /* width: 33%; */
    font-size: 16px;
    color: #0d0e0f;
    border: none;
    font-weight: 500;
  }
  .homesecsale__container1 {
    width: 100%;
  }
  .custom-dropdown {
    /* border: 1px solid #FFEBB0; */
    width: 60%;
    border-radius: 12px;
  }

  .homesecsale h6 img {
    display: none;
  }
  .homesecsale h6 i {
    display: none;
  }
  .homesecsale input {
    font-size: 1vmax;
    padding: 12px 16px;
    border: 1px solid #ffebb0;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    color: #a6a7a8;
  }
  .homesecsale button {
    background-color: #e8ae00;
    border: none;
    border-radius: 12px;
    font-size: 1.5vmax;
  }
  .homesecsale .salebtn {
    color: #e8ae00;
    text-decoration: underline;
    background-color: white;
    border: none;
    text-decoration-skip-ink: none; /* Adjust this property */
    padding-bottom: 2px;
    line-height: 1.5; /* Increase line height to create more space between text and underline */

  }
}
@media only screen and (min-width: 360px) and (max-width:380px) {
  .homesec1{
    height: 55vmax;
  }
  .homesec1 h1{
    top:33%;
  }
  .homesec1 p{
    top:60%
  }
  .featuredTitle{
    margin-top: 140px !important;
  }
}


@media (min-width: 768px) and (max-width: 1400px) {
  .maillogo {
    height: 58px;
    width: 173px;
  }

  .homesec1 {
    width: 100%;
    height: 45vmax;
    position: relative;
  }
  .homesecoverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
  }
  .homesec1img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .homesec1 h1 {
    width: 70%;
    top: 30%;
  }

  .homesec1 p {
    width: 75%;
    position: absolute;
    top: 68%;
    color: white;
    font-weight: 500;
    font-size: 2.5vmax;
  }
  .homesecsale {
    width: 90%;
    position: absolute;
    bottom: -5vmax;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    /* left: 8%; */
    padding: 5px;
    box-shadow: 0px 8px 11px -4px #2d36430a;
  }

  .homesecsale h6 {
    border: 1.5px solid #fff9e6;
    padding: 12px 16px;
  }
  .homesecsale h6 i {
    display: none;
  }
  .homesecsale button {
    background-color: #e8ae00;
    border: none;
    border-radius: 12px;
  }
  .homesec1__subContainer1 h1 {
    font-size: 75px;
    font-weight: 800;
    line-height: 88px;
  }
  .custom-dropdown {
    width: 25%;
  }
  .custom-dropdown input {
    height: 45px;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .homesec1 p {
    top: 63%;
  }
}

/* Featured component css */
.featuredtext {
  font-size: 2.5vmax;
  margin-top: 56px;
  margin-bottom: 27px;
  margin-left: 6vmax;
  font-family: "Exo";
  font-weight: 600;
  color: #0d0e0f;
  @media (max-width: 700px) {
    margin-left: 3vmax;
  }
}
.blogText {
  font-size: 2.5vmax;
  margin-top: 56px;
  margin-bottom: 27px;
  margin-left: 6vmax;
  font-family: "Exo";
  font-weight: 600;
  color: #0d0e0f;
  @media (max-width: 700px) {
    margin-left: 3vmax;
    margin-top: 30px;
    font-size: 4vmax;
  }
}
.featuredcardhome {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: normal;
  /* flex-wrap: wrap; */
  margin-bottom: 40px;
  flex-direction: column;
}
.featuredimghome {
  position: relative;
  width: 100%;
}
.featuredimghome h3 {
  position: absolute;
  top: 1.5vmax;
  font-size: 1vmax;
  background-color: white;
  border-top-right-radius: 1vmax;
  border-bottom-right-radius: 1vmax;
  padding: 0.5vmax 1vmax;
}
.featuredimghome .ellipse {
  width: 10px;
  height: 10px;
  display: inline;
  margin-right: 5px;
}
.featuredimghome i {
  height: 2.5vmax;
  width: 2.5vmax;
  position: absolute;
  top: 1.5vmax;
  right: 1vmax;
  font-size: 1.8vmax;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  /* padding-top: 0.2vmax; */
}
.featuredcard1home {
  width: 25%;
  margin-left: 2px;
  margin-top: 20px;
  /* border: 1px solid rgb(220, 217, 217); */
  padding: 0px, 0px, 16px, 0px;
  border-radius: 12px;
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* box-shadow: 0px 0px 1px 0px #0C1A4B3D; */
}

.featuredcard1home img {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.featureinfodiv {
  width: 95%;
}
.featuredcard1home h4 {
  font-size: 1.6vmax;
  font-weight: 700;
  margin-top: 1vmax;
}
.featuredcard1home h5 {
  font-size: 1.2vmax;
  color: #135966;
}
.featuredcard1home p {
  font-size: 0.8vmax;
  color: #414141;
  font-size: 1vmax;
}
.featuredblackhome {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  border-radius: 12px;
  margin-bottom: 1vmax;
}
.featuredblackhome p {
  margin-top: 1vmax;
  font-size: 1vmax;
  color: white;
}
.featuredblackhome p img {
  width: 1.5vmax;
  margin-right: 5px;
  display: inline;
}
.nextPagination,
.prevPagination {
  border: 1.5px solid #E8AE00;
  border-radius: 12px;
  padding: 5px;
  opacity: 20%;
  cursor: pointer;
  font-size: 2rem;
}

.nextPagination.active,
.prevPagination.active {
  opacity: 100%;
}
@media (max-width: 767px) {
  .featuredtext {
    font-size:27px;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 2vmax;
  }
  .featuredTitle{
    margin-top: 170px;
  }
 

  .featuredcardhome {
    /* width: 50vmax; */
    justify-content: start;
    overflow-x: scroll;
    white-space: nowrap;
    align-items: flex-start !important;
  }
  .featuredblackhome::-webkit-scrollbar {
    display: none;
  }
  .featuredimghome {
    width: 100%;
    position: relative;
    /* margin-top: 10px; */
  }
  .featuredimghome h3 {
    top: 3vmax;
    font-size: 12px;
    background-color: white;
    padding: 0.7vmax;
  }
  .featuredimghome i {
    height: 5vmax;
    width: 5vmax;
    font-size: 3vmax;
    align-items: center;
  }
  .featuredcard1home {
    width: 100%;

    margin-left: 3vmax;
    width: 300px !important;
    height: max-content !important;
  }
  .featuredcard1home h4 {
    font-size: 20px !important;
  }
  .featuredcard1home h5 {
    font-size: 16px !important;
  }
  .featuredcard1home p {
    font-size: 14px !important;
  }
  .featuredblackhome {
    margin-bottom: 1vmax;
  }
  .featuredblackhome p {
    margin-top: 1.6vmax;
    font-size: 14px !important;
  }
  .featuredblackhome p img {
    width: 2.2vmax;
    height: 2.5vmax;
    margin-right: 5px;
    display: inline;
  }
  .featureinfodiv{
    margin-top: 10px;
    margin-left: 8px !important;
    margin: 10px ;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .featuredtext {
    font-size: 4vmax;
    margin-top: 80px;
    margin-bottom: 0px;
    margin-left: 3vmax;
  }
  .featuredcardhome {
    width: 100%;
    justify-content: start;
    overflow-x: auto; /* Allow horizontal scrolling when needed */
    overflow-y: hidden; /* Hide vertical scrollbar */
    white-space: nowrap;
    margin-left: 0 !important;
  }

  /* Style the scrollbar */
  .featuredcardhome::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .featuredImg__detail{
    height: 250px !important;
  }
  .property-description p{
    font-size: 14px !important;
  }
  .featuredimghome {
    width: 100%;
    position: relative;
  }
  .featuredimghome h3 {
    font-size: 1.2vmax;
    background-color: white;
    padding: 0.7vmax;
  }
  .featuredimghome i {
    height: 3vmax;
    width: 3vmax;
    align-items: center;
  }
  .featuredcard1home {
    width: max-content;
    margin-left: 1.3vmax;
  }
  .featuredcard1home h4 {
    font-size: 2vmax;
  }
  .featuredcard1home h5 {
    font-size: 1.6vmax;
  }
  .featuredcard1home p {
    font-size: 1vmax;
  }
  .featuredblackhome p {
    margin-top: 1.4vmax;
    font-size: 1.2vmax;
  }
  .featuredImg__main {
    height: 200px !important;
  }
}

/* Choose component css */
.chooseheading {
  width: 90%;
  font-size: 2.5vmax;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  margin-left: 2vmax;
  font-family: "Exo", sans-serif;
  font-weight: 600;
}
.choosecard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ebfffd;
  margin-top: 1.5vmax;
  padding-bottom: 1vmax;
  height: 630px;
}
.choosecard .choseedisplay {
  width: 88%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.choosecard .choseedisplay .choosecard1 {
  width: 49%;
  background-color: white;
  border-radius: 12px;
  padding: 0.5vmax;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1vmax;
  margin-bottom: 0.5vmax;
  box-shadow: 0px 8px 11px -4px #2d36430a, 0px 20px 24px -4px #ffebb00a;
  height: 140px;
}
.choosecard1 .choosenamepara {
  width: 90%;
  padding-top: 21px;
  h6 {
    font-size: 18px;
    color: #0d0e0f;
    font-weight: 600;
    @media (max-width: 700px) {
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
}

.iconbox {
  width: 88px;
  height: 88px;
  background-color: black;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconbox img {
  width: 40px;
}

@media (max-width: 767px) {
  .choosecard{
    margin-top: 40px;
    height: 680px;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3vmax;
  }
  .chooseheading {
    font-size: 4vmax;
    margin-left: 0px;
  }
  .choosecard .choseedisplay .choosecard1 {
    width: 100%;
    height: 80px;
    padding: 16px;
  }
  .choosecard1 {
    width: 85%;
    background-color: red;
  }
  .choosecard1 p {
    display: none;
  }
  .iconbox {
    width: 48px;
    height: 48px;
  }
  .iconbox i {
    font-size: 2.5vmax;
  }
  .choosecard1 .choosenamepara{
    padding-top: 0px;
  }
  .iconbox img{
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .choosecard {
    height: 1170px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .choosecard .choosecard1 {
    width: 90%;
    padding: 1vmax;
    /* background-color: red; */
  }
  .choosecard1 .choosenamepara {
    width: 80%;
    padding-top: 10px;
  }
  .choosenamepara h6 {
    font-size: 2vmax;
    font-weight: 600;
  }
  .choosecard1 p {
    font-size: 16px;
  }
  .iconbox {
    width: 88px;
    height: 88px;
  }
  .iconbox img {
    width: 5vmax;
  }
  .choosecard .choseedisplay {
    flex-direction: column;
    margin-left: 3vmax;
    width: 92%;
  }
  .chooseheading {
    font-size: 4vmax;
    margin-top: 4vmax;
    margin-left: 3vmax;
  }
  .choosecard .choseedisplay .choosecard1 {
    width: 100%;
    justify-content: flex-start;
    padding-left: 1.7vmax;
  }
}

/* Blog component css */

.scroll-animation {
  flex-shrink: 0;
  /* width: 100%; */
  display: flex;
  column-gap: 50px;
  flex-wrap: nowrap;
  padding-bottom: 10px;
  /* overflow: auto; */

  /* padding-left: 50px; */
  /* animation: scroll 30s linear infinite;  */
}

.scroll-grp {
  display: flex;
  /* width: 100%; */
  overflow: auto;
  margin-left: 5rem;
  margin-right: 5rem;
}
.scroll-grp::-webkit-scrollbar {
  display: none;
}
/* .scroll-grp:hover{
    .scroll-animation{
        animation-play-state: paused;
    }
} */

.blogcard1 {
  width: 393px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding-bottom: 0.2vmax;
  margin-top: 1vmax;
  box-shadow: 0px 3px 8px -1px #3232470d, 0px 0px 1px 0px #0c1a4b3d;
  cursor: pointer;
}
@keyframes scroll {
  /* 0% {
         transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 8));
    } */
  from {
    left: translate(0);
  }

  to {
    transform: translateX(-100%);
  }
}
.blogcard1 img {
  width: 100%;
  height: 18vmax;
  border-radius: 12px;
}
.blogcard1 h4 {
  width: 92%;

  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.blogcard1 p {
  width: 92%;
  /* height: 2vmax; */
  font-size: 14px;
  margin-top: 0.2vmax;
}
.hakkies {
  width: 100%;
  margin-top: 3vmax;
}
.hakkies img {
  width: 3vmax;
  height: 3vmax;
}
.hakkies h5 {
  font-size: 1vmax;
  font-weight: 700;
}
.hakkies p {
  font-size: 1vmax;
}

@media (max-width: 767px) {
  .scroll-grp {
    margin-left: 3vmax;
    width: 100%;
  }
  .scroll-animation {
    width: 300vmax;
    column-gap: 18px;
  }
  .blogcard1 {
    width: 34vmax;
    height: 290px;
  }
  .blogcard1 img {
    /* height: 24vmax; */
    height: 215px;
  }

  .blogcard1 h4 {
    height: auto;
    font-size: 1.6vmax;
    font-weight: 700;
  }
  .blogcard1 p {
    height: auto;
    font-size: 14px;
  }
  .hakkies {
    width: 100%;
    margin-top: 0vmax;
  }
  .hakkies img {
    width: 3vmax;
    height: 3vmax;
  }
  .hakkies h5 {
    font-size: 1vmax;
    font-weight: 700;
  }
  .hakkies p {
    font-size: 1vmax;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .scroll-grp {
    margin-left: 3vmax;
    margin-right: 0;
    margin-top: 3vmax;
  }
  .scroll-animation {
    width: 300vmax;
  }

  .blogcard1 {
    width: 35vmax;
    height: auto;
  }
  .blogcard1 img {
    height: 25vmax;
  }
  .blogcard1 h4 {
    height: auto;
    font-size: 18px;
    font-weight: 600;
  }
  .blogcard1 p {
    height: auto;
    font-size: 15px;
  }
  .hakkies {
    width: 100%;
    margin-top: 0vmax;
  }
  .hakkies img {
    width: 3vmax;
    height: 3vmax;
  }
  .hakkies h5 {
    font-size: 1vmax;
    font-weight: 700;
  }
  .hakkies p {
    font-size: 1vmax;
  }
  .CustomerReview .customer .CustomerTop p{
    margin-left: 3.5vmax !important;
  }
}

/* properties component css */
.linecard {
  height: 331px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  /* margin-left: 4.5vmax; */
  margin-top: 4vmax;
}
.linecard img {
  width: 1440px;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.line {
  border-right: 1px solid #007781;
  padding: 1.5vmax;
  margin-top: 3vmax;
  margin-bottom: 2vmax;
  padding-right: 90px;
  padding-left: 0;
  text-align: center;
}
.line h3 {
  color: #007781;
  font-weight: 700;
  font-size: 48px;
  @media (max-width: 700px) {
    font-size: 24px;
  }
}
.lastline {
  padding: 1.5vmax;
  margin-top: 3vmax;
  margin-bottom: 2vmax;
  text-align: center;
}
.lastline h3 {
  color: #007781;
  font-weight: 700;
  font-size: 48px;
  @media (max-width: 700px) {
    font-size: 24px;
  }
}
.line p {
  font-size: 28px;
  @media (max-width: 700px) {
    font-size: 14px;
  }
}
.lastline p {
  font-size: 28px;
  @media (max-width: 700px) {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .linecard {
    width: 100%;
    height: 28vmax;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: start;
    position: relative;
    align-items: center;
    /* background-color: red; */
    overflow-x: scroll;
    white-space: nowrap;
    margin-left: 0vmax;
    margin-top: 10px;
  }
  .linecard::-webkit-scrollbar {
    scroll-behavior: smooth;
  }
  .line {
    /* border-right: 0px solid #007781; */
    padding: 4vmax;
    @media (max-width: 700px) {
      padding: 2vmax;
    }
  }
  .lastline {
    padding: 4vmax;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .line p {
    font-size: 1.8vmax;
  }
  .lastline p {
    font-size: 1.8vmax;
  }
  .linecard {
    width: 100%;
    margin-left: 0.5vmax;
  }
  .line {
    padding-right: 20px;
  }
}

/* Customer Review css  */

.CustomerReview {
  width: 100%;
  overflow: hidden;
}
.CustomerReview .customer {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  background-color: #ebfffd;
  padding-bottom: 4vmax;
  padding-bottom: 120px;
}
.customerContainer1{
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}
.CustomerReview .customer .CustomerTop {
  width: 100%;
  height: auto;
  margin-top: 35px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* flex-direction: column; */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
}
.CustomerReview .customer .customerMedium button {
  background-color: #61fffe;
  border: none;
  font-weight: 500;
  /* padding: 0.4vmax 0.8vmax; */
  border-radius: 12px;
  margin-right: 85px;
  padding: 12px 16px 12px 16px;
  font-size: 18px;
}
.CustomerReview .customer .CustomerTop h2 {
  margin-left: 85px;
  font-size: 2.5vmax;
  font-weight: 600;
  /* width: 100%; */
}

.CustomerReview .customer .customerMedium {
  /* width: 100%; */
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 7vmax;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.CustomerReview .customer .CustomerTop p {
  font-size: 18px;
  position: relative;
  /* right: 1.5vmax; */
  top: 0.5vmax;
  font-weight: 500;
  margin-left: 5.4vmax;
  @media (max-width: 700px) {
    margin-left: 2vmax;
    font-size: 16px;
  }
}
@media(max-width: 768){
  .CustomerReview .customer .CustomerTop p {
   
    margin-left: 2vmax;
   
  }}
.CustomerReview .customer .customerBottom-grp {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.CustomerReview .customer .customerBottom-grp:hover {
  .customerBottom {
    animation-play-state: paused;
  }
}
.CustomerReview .customer .customerBottom-grp .customerBottom {
  flex-shrink: 0;
  display: flex;
  column-gap: 20px;
  flex-wrap: nowrap;
  padding-left: 20px;
  animation: scroll 120s linear infinite;
  margin-top: 1vmax;
  margin-bottom: 1vmax;
}
.CustomerReview .customer .customerBottom-grp .customerBottom:hover {
}
@keyframes review {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box {
  width: 330px;
  height: 230px;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 8px 11px -4px #2d36430a, 0px 20px 24px -4px #ffebb00a;
  /* align-items: center; */
  padding: 20px;
  padding-top: 0px;
  padding-left: 1px;
  /* justify-content: center; */
  margin-left: 2vmax;
  cursor: pointer;
  @media (max-width: 700px) {
    width: 270px;
  }
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box .Btop {
  width: 330px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media(max-width: 768px){
    padding-left: 5px;
  }
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box .Btop img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(10px);
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box .Btop h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 0.5vmax;
  /* margin-left: 0.5vmax; */
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box h6 {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  /* margin-left: 15%; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  transform: translateY(-10px);
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box p {
  width: 90%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.CustomerReview .customer .customerBottom-grp .customerBottom .Box .Bbtm {
  /* width: 290px; */
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.7vmax;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 320px !important;
  }
}
.CustomerReview
  .customer
  .customerBottom-grp
  .customerBottom
  .Box
  .Bbtm
  .icons
  i {
  font-size: 26px;
  color: #43a048;
}
.CustomerReview
  .customer
  .customerBottom-grp
  .customerBottom
  .Box
  .Bbtm
  .arrow
  i {
  border: 1px solid black;
  padding: 0.4vmax;
  border-radius: 50px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .CustomerReview .customer .CustomerTop h2 {
    margin-left: 15px;
    font-size: 4vmax;
  }
  .CustomerReview .customer .CustomerTop button {
    margin-left: 15px;
    margin-top: 6px;
    height: 48px;
    font-size: 14px;
  }
  .CustomerReview .customer .customerMedium {
    width: 100%;
    padding-left: 0;
    justify-content: normal;
  }
  .CustomerReview .customer .customerMedium p {
    font-size: 2vmax;
    margin-top: 2vmax;
  }
  .CustomerReview .customer .customerBottom-grp .customerBottom .Box .Btop img {
    margin-right: 2vmax;
  }
  .CustomerReview .customer .customerBottom-grp .customerBottom .Box h6 {
    /* margin-left: 60px; */
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .CustomerReview .customer .CustomerTop {
    width: 100%;
    height: auto;
  }
  .CustomerReview .customer .CustomerTop h2 {
    font-size: 4.5vmax;

    margin-left: 3vmax;
  }
  .CustomerReview .customer .CustomerTop button {
    margin-right: 3vmax;
    width: 200px;
  }
  .CustomerReview .customer .customerMedium {
    /* width: 0; */
    padding-left: 3vmax;
    font-size: 2vmax;
    /* justify-content: start; */
  }
  .customerContainer1 {
    width: 100%;
  }
}
