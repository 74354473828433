 .ellipseinsight{
    width: 10px;
    height: 10px;
    display: inline;
    margin-right: 5px;
    
}
.Insightsmain{
    width: 100%;
    height: 50vmax;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Insightsmain .insightmid{
    width: 100%;
    height: 50vmax;
    margin-top: 4vmax;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Insightsmain .insightmid .insightpop{
    width: 35%;
    height: 35vmax;
}
.insightContainerMain{
    border-radius: 20px !important;
}
.insightTitle{
    font-size: 24px;
    font-family: "Exo";
}
.insightSubTitle{
    font-size: 18px;
    font-weight: 400;
    font-family: "Exo";
    color: #0D0E0F;
}
.insightButton1{
    border: 1.5px solid #E8AE00;
    border-radius: 12px;
    height: 50px;
    padding: 12px 16px 12px 16px;
    font-size: 18px;
    font-weight: 500;
}
.insightButton2{
    border-radius: 12px;
    height: 50px;
    padding: 12px 16px 12px 16px;
    font-size: 18px;
    font-weight: 500;
    background-color: #E8AE00;
}
@media (max-width:767px){
    .Insightsmain{
        height: auto;
    }
    .Insightsmain .insightmid{
        width: 100%;
        height: auto;
        margin-top: 15vmax;
    }
    .Insightsmain .insightmid .insightpop{
        width: 100%;
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .Insightsmain .insightmid{
        width: 100%;
        height: auto;
        margin-top: 20vmax;
    }
    .Insightsmain .insightmid .insightpop{
        width: 50%;
    }
    .insightContainerMain{
        height: 48vmax;
    }
}