.SavePropertMain {
  width: 100%;
  margin-bottom: 20vmax
}
.SavePropertMain .Savedmain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SavePropertMain .Savedmain .sTopOne {
  width: 100%;
  display: flex;
  background-color: #ebfffd;
  align-items: center;
  justify-content: space-between;
}
.SavePropertMain .Savedmain .sTopOne .sLEFT {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 2vmax;
}
.SavePropertMain .Savedmain .sTopOne .sLEFT h5 {
  font-size: 2.2vmax;
  font-weight: 700;
}
.SavePropertMain .Savedmain .sTopOne .sLEFT button {
  border: 1px solid #01b8c6;
  padding: 0.3vmax 1vmax;
  border-radius: 30px;
}

.SavePropertMain .Savedmain .sTopOne .sRIGHT {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.SavePropertMain .Savedmain .sTopOne .sRIGHT img {
  width: 70%;
}
.savedPropImg{
  height:246px;
  width: 510px;
}
.savedPropPrice{
  font-size: 36px;
  @media(max-width:768px){
    font-size: 14px;
  }
}
/* -------------------------- */
.SavePropertMain .Savedmain .sTopLast {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.SavePropertMain .Savedmain .sTopLast .sHeadingP {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SavePropertMain .Savedmain .sTopLast .sHeadingP h5 {
  font-size: 1.7vmax;
  width: 30%;
  font-weight: 600;
}
.SavePropertMain .Savedmain .sTopLast .sHeadingP .links {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.SavePropertMain .Savedmain .sTopLast .sHeadingP .links span {
  margin: 1vmax;
  transition: 0.5s;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0;
}
.SavePropertMain .Savedmain .sTopLast .sHeadingP .links span:hover {
  color: rgb(118, 153, 241);
}

.links .active-link {
  color: #098895;
  text-decoration: underline;
}

/* ------------------------- */
.SavePropertMain .Savedmain .savedcrd {
  width: 100%;
  margin-top: 2vmax;
}
.SavePropertMain .Savedmain {
  width: 100%;
  display: flex;
  /* margin-top: 2vmax; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 7vmax;
}
.SavePropertMain .Savedmain .sCHouse {
  width: 90%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
  box-shadow: 2px 2px 5px 2px rgb(222, 222, 222);
}
.SavePropertMain .Savedmain .sCHouse .scLeft {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SavePropertMain .Savedmain .sCHouse .scLeft img {
  width: 100%;
  height: 18vmax;
}
.SavePropertMain .Savedmain .sCHouse .scRight {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.SavePropertMain .Savedmain .sCHouse .scRight .tp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vmax;
  padding-left: 20px;
  margin-left: 20px;
}
.SavePropertMain .Savedmain .sCHouse .scRight .tp .price {
  width: 50%;
}
.SavePropertMain .Savedmain .sCHouse .scRight .tp .price h5 {
  font-weight: 700;
  font-size: 2vmax;
}
.SavePropertMain .Savedmain .sCHouse .scRight .tp .sicons {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.SavePropertMain .Savedmain .sCHouse .scRight .tp .sicons i {
  font-size: 1.3vmax;
  cursor: pointer;
}
.SavePropertMain .Savedmain .sCHouse .scRight .smd {
  width: 100%;
  margin-bottom: 0.2vmax;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.SavePropertMain .Savedmain .sCHouse .scRight .smd h3 {
  color: rgb(0, 150, 150);
}
.SavePropertMain .Savedmain .sCHouse .scRight .sbm {
  width: 100%;
  display: flex;
  margin-bottom: 0.5vmax;
  border-radius: 10px;
  background-color: #cdcdcd1f;
  align-items: center;
  justify-content: space-evenly;
}
.SavePropertMain .Savedmain .sCHouse .scRight .lt {
  width: 100%;
  display: flex;
  margin-bottom: 2vmax;
  justify-content: flex-start;
}
.SavePropertMain .Savedmain .sCHouse .scRight .lt button {
  border: 2px solid #9edf06;
  padding: 0.5vmax 2vmax;
  border-radius: 5px;
  margin: 0.5vmax;
}

.savedPropertyyy {
  width: 23vmax;
  position: absolute;
  background-color: rgb(0, 0, 0);
  color: #fff;
  padding-bottom: 1vmax;
  border-radius: 1vmax;
  top: 27vmax;
  right: 10vmax;
}
.savedPropertyyy h6 {
  margin-left: 1.5vmax;
  margin-top: 1vmax;
  font-size: 1.3vmax;
}
.savedPropertyyy i {
  margin-left: 1.5vmax;
  font-size: 1.6vmax;
  cursor: pointer;
}
.savedPropertyyy button {
  border: none;
  border-radius: 2vmax;
  margin-left: 1vmax;
  font-size: 1vmax;
}
.savedPropertyyy .ri-close-line {
  position: absolute;
  right: -5px;
  top: -1vmax;
  font-size: 1.5vmax;
  color: black;
  background-color: rgb(243, 223, 223);
  border-radius: 50%;
  padding: 0vmax 0.6vmax;
}
.savedPropertyyy button i {
  margin-right: 1vmax;
  margin-left:0;
  font-size: 1.3vmax;
}

@media (max-width: 768px) {
  /* ------------------------ */

  .SavePropertMain .Savedmain .sTopOne {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT {
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT h5 {
    font-size: 3vmax;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT button {
    padding: 0.8vmax 1vmax;
  }

  .SavePropertMain .Savedmain .sTopOne .sRIGHT {
    display: none;
  }

  /* --------------------------- */
  .SavePropertMain .Savedmain .savedcrd {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .SavePropertMain .Savedmain {
    width: 100%;
    /* align-items: center; */
    justify-content: center;
  }

  .SavePropertMain .Savedmain .sCHouse {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .SavePropertMain .Savedmain .sCHouse .scLeft {
    width: 100%;
    align-items: center;
  }
  .SavePropertMain .Savedmain .sCHouse .scLeft img {
    width: 100%;
    height: auto;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight {
    width: 100%;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight .tp {
    margin-top: 1vmax;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight .tp .price h5 {
    font-size: 3vmax;
  }

  .SavePropertMain .Savedmain .sCHouse .scRight .tp .sicons i {
    font-size: 2.5vmax;
  }

  /* ---------------- */
  .SavePropertMain .Savedmain .sTopLast {
    width: 100%;
    /* align-items: center; */
    margin-top: 2vmax;

    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP {
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP h5 {
    font-size: 16px;
    width: 100%;
    font-weight: 700;
    /* margin-top: 2vmax; */
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP .links span{
    font-size: 14px;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP .links {
    width: 100%;
    display: flex;
    /* justify-content: space-evenly; */
  }

  .savedPropertyyy {
    width: 80%;
    padding-bottom: 3vmax;
    padding-top: 3vmax;
    top: 27vmax;
    /* margin-top: 50vmax; */
    right: 5vmax;
  }
  .savedPropertyyy h6 {
    font-size: 2.5vmax;
  }
  .savedPropertyyy i {
    font-size: 3vmax;
  }
  .savedPropertyyy button {
    font-size: 2.5vmax;
  }
  .savedPropertyyy .ri-close-line {
    font-size: 2.5vmax;
  }
  .savedPropertyyy button i {
    font-size: 2.5vmax;
  }
}

@media (min-width: 769px) and (max-width: 1050px) {
  .SavePropertMain .Savedmain .sTopOne {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT {
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT h5 {
    font-size: 3vmax;
  }
  .SavePropertMain .Savedmain .sTopOne .sLEFT button {
    padding: 0.8vmax 1vmax;
  }

  .SavePropertMain .Savedmain .sTopOne .sRIGHT {
    display: none;
  }

  /* --------------------------- */
  .SavePropertMain .Savedmain .savedcrd {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .SavePropertMain .Savedmain {
    width: 100%;
    /* align-items: center; */
    justify-content: center;
  }
  .SavePropertMain .Savedmain h6{
    margin-left: 1vmax;
  }
  .loadingMsg{
    margin-left: 3vmax;
  }
  .SavePropertMain .Savedmain .sCHouse {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .SavePropertMain .Savedmain .sCHouse .scLeft {
    width: 100%;
    align-items: center;
  }
  .SavePropertMain .Savedmain .sCHouse .scLeft img {
    width: 100%;
    height: auto;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight {
    width: 100%;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight .tp {
    margin-top: 1vmax;
  }
  .SavePropertMain .Savedmain .sCHouse .scRight .tp .price h5 {
    font-size: 3vmax;
  }

  .SavePropertMain .Savedmain .sCHouse .scRight .tp .sicons i {
    font-size: 2.5vmax;
  }
  .SavePropertMain .Savedmain .sTopLast {
    width: 100%;
    margin-top: 1vmax;

    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP {
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP h5 {
    font-size: 28px;
    width: 100%;
    font-weight: 700;
  }
  .SavePropertMain .Savedmain .sTopLast .sHeadingP .links {
    width: 100%;
    display: flex;
    /* justify-content: space-evenly; */
  }
}
